// General
import "./about-me.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMeDisableState,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateAboutMeIndex,
  updateAboutMeList,
  updateSelectedAboutMe,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import {
  updateAboutMe,
  updateIsEditAboutMeNext,
  updateUpdateProfileDialogView,
} from "../../../../redux/store/editProfileStore";
import { updateUpdateRegistrationAboutMeDialog } from "../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const AboutMeDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const aboutMeState = useSelector((state) => state.registration.aboutMeState);
  const aboutMeDisableState = useSelector(
    (state) => state.registration.aboutMeDisableState
  );
  const aboutMeList = useSelector((state) => state.registration.aboutMeList);
  const selectedAboutMe = useSelector(
    (state) => state.registration.selectedAboutMe
  );
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
    } else if (getJourneyQuestionsError) {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 12 ? true : i > 12 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Lifecycle | Check for update | aboutMeState
  useEffect(() => {
    // Check if aboutMeState has question and answers, if have, update aboutMeDisableState to false
    let temp = [...aboutMeState];
    let aboutMeDisableStateTemp = [...aboutMeDisableState];

    temp.forEach((item, index) => {
      if (item?.question && item?.answers?.length > 0) {
        if (index + 1 < aboutMeState.length) {
          aboutMeDisableStateTemp[index + 1] = false;
        }
      }
    });

    dispatch(updateAboutMeDisableState(aboutMeDisableStateTemp));
  }, [aboutMeState]);

  // Event Handlers | Button
  const onEditSection = (section, index) => {
    if (section) {
      const obj = {
        name: section,
        data: aboutMeList?.find((item) => item?.name === section)?.data,
      };
      dispatch(updateAboutMe(obj));
      dispatch(updateIsEditAboutMeNext(true));
    }

    if (index !== null || index !== undefined) {
      dispatch(updateAboutMeIndex(index));
    }

    dispatch(
      updateUpdateProfileDialogView(editProfileConst.profileDialogView.aboutMe)
    );
    dispatch(updateUpdateRegistrationAboutMeDialog(true));
  };
  const onClearSection = (section) => {
    const aboutMeIndex = aboutMeList?.findIndex(
      (item) => item?.name === section
    );
    let temp = JSON.parse(JSON.stringify(aboutMeList));
    temp[aboutMeIndex].data = null;
    dispatch(updateAboutMeList(temp));

    const selectedAboutMeIndex = selectedAboutMe?.findIndex(
      (item) => item?.name === section
    );
    let tempSelectedAboutMe = JSON.parse(JSON.stringify(selectedAboutMe));
    tempSelectedAboutMe[selectedAboutMeIndex].name = null;
    tempSelectedAboutMe[selectedAboutMeIndex].data = null;
    dispatch(updateSelectedAboutMe(tempSelectedAboutMe));

    dispatch(
      updateUpdateProfileDialogView(editProfileConst.profileDialogView.aboutMe)
    );
    dispatch(updateUpdateRegistrationAboutMeDialog(true));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.13-62.13.1",
      },
    });

    let obj = {
      journey_step: "13",
      journey_data: {
        profile: {
          personality_traits: null,
          personality_type: null,
          star_sign: null,
          dominant_or_submissive: null,
          fitness_level: null,
          hobbies: null,
        },
      },
    };

    selectedAboutMe?.forEach((item) => {
      if (item?.data) {
        obj.journey_data.profile[item?.name] = Array.isArray(item?.data)
          ? item?.data?.map((answer) => answer?.id)
          : item?.data?.id;
      }
    });
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.13-62.13.2",
      },
    });

    const obj = {
      journey_step: "13",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      selectedAboutMe.every((item) => item?.name && item?.data) &&
      !updateJourneyLoading
    );
  };

  return (
    <div id="details-signup-about-me-details-subcomponent">
      <div className="top-container">
        <div className="about-me-details-label">
          <Trans
            i18nKey={"registration.about_me.title"}
            components={{ br: <br /> }}
          />
        </div>

        <div className="about-me-details-description-label">
          <Trans
            i18nKey={"registration.about_me.description"}
            components={{ br: <br /> }}
          />
        </div>

        <div className="about-me-details-select-up-to-description">
          {t("registration.about_me.select_n_questions_in_total", { n: 3 })}
        </div>

        {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
          <Spinner />
        ) : (
          <div className="about-me-body-container">
            {selectedAboutMe?.map((item, index) => {
              if (index < 3) {
                return (
                  <div
                    className={`about-me-item-container ${
                      item?.question ? "add-gap" : ""
                    }`}
                    key={index}
                  >
                    {item?.data ? (
                      <div className="about-me-selected-question-container">
                        <div
                          className={`about-me-selected-question-label ${
                            item?.data ? "" : "selected-question-full-width"
                          }`}
                          onClick={() => onEditSection(item?.name, index)}
                        >
                          {`${item?.data ? "" : `Question ${index + 1}:`} ${t(
                            `registration.about_me.${item?.name}`
                          )}`}
                        </div>

                        <div
                          className={`about-me-selected-answers-container ${
                            item?.data ? "" : "selected-question-no-width"
                          }`}
                        >
                          {Array.isArray(item?.data) ? (
                            item?.data?.map((answer, selectedIndex) => (
                              <div
                                className="about-me-selected-answer-container"
                                onClick={() => onEditSection(item?.name, index)}
                                key={selectedIndex}
                              >
                                {answer?.display_locale}
                                <CloseIcon className="selected-answer-close-icon" />
                              </div>
                            ))
                          ) : (
                            <div
                              className="about-me-selected-answer-container"
                              onClick={() => onEditSection(item?.name, index)}
                            >
                              {item?.data?.display_locale}
                              <CloseIcon className="selected-answer-close-icon" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`about-me-item ${
                          isMobile ? "partial-width" : "fix-width"
                        }`}
                        // onClick={() => onSelectAboutMe(index)}
                        onClick={() => onEditSection(null, index)}
                        key={index}
                      >
                        <div className="about-me-item-label">
                          <div className="question-label">
                            {t("registration.about_me.question")} {index + 1}:
                          </div>
                          <div className="item-label">
                            {t(
                              "registration.about_me.select_the_question_and_answer"
                            )}
                          </div>
                        </div>

                        <KeyboardArrowDownIcon className="about-me-item-icon" />
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutMeDetails;
