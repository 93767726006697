// General
import "./search.scss";
import { useEffect, useRef } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateScrollYPosition } from "../../../redux/store/searchStore";
import {
  updateMissingTorDialog,
  updateUpgradeToPremiumDialog,
} from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import RestartJourneyNotice from "../../shared/restart-journey-notice/restart-journey-notice";
import CategoryTab from "./category-tab/category-tab";
import FiltersSelected from "./filters-selected/filters-selected";
import IncompleteProfile from "./incomplete-profile/incomplete-profile";
import VerifyEmail from "./verify-email/verify-email";
import Banner from "../../shared/elements/banner/banner";
import SearchFilter from "./search-filter/search-filter";
import SearchCardContainer from "./search-card-container/search-card-container";
import UpgradeToPremiumDialog from "../../shared/dialog-content/upgrade-to-premium-dialog/upgrade-to-premium-dialog";
import MissingTermsOfRelationshipDialog from "../../shared/dialog-content/missing-terms-of-relationship-dialog/missing-terms-of-relationship-dialog";
import ModerationStatus from "../../profile/edit/moderation-status/moderation-status";

const Search = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const scrollContainerRef = useRef(null);
  let timeoutId = null;
  const isMounted = useRef(false);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const filteredLists = useSelector((state) => state.search.filteredLists);
  const recentlyActiveLists = useSelector(
    (state) => state.search.recentlyActiveLists
  );
  const nearbyLists = useSelector((state) => state.search.nearbyLists);
  const newestLists = useSelector((state) => state.search.newestLists);
  const collegeLists = useSelector((state) => state.search.collegeLists);
  const premiumLists = useSelector((state) => state.search.premiumLists);
  const selectedCategoryTab = useSelector(
    (state) => state.search.selectedCategoryTab
  );
  const scrollYPosition = useSelector((state) => state.search.scrollYPosition);
  const searchScrollToTopPassthrough = useSelector(
    (state) => state.search.searchScrollToTopPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-7",
      },
    });

    getProfile(null, true); // Change to false due to membershipType not updated fast enough from backend
  }, [isLoggedIn]);

  // Lifecycle | Mounted | scrollYPosition
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const savedScrollPosition = scrollYPosition;

    if (scrollContainer) {
      if (savedScrollPosition) {
        scrollContainer.scrollTop = parseInt(savedScrollPosition, 10);
      } else {
        scrollContainer.scrollTop = 0;
      }

      scrollContainer?.addEventListener("scroll", handleScroll);

      return () => {
        scrollContainer?.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        if (!getProfileData?.data?.tor_tags) {
          // dispatch(updateMissingTorDialog(true));
        }

        // Show "search-page-fullscreen-general-dialog-last-shown" if it has been more than 1 day since last shown
        const lastShown =
          sessionService?.getSearchPageFullscreenGeneralDialogLastShown();

        if (!lastShown || moment().diff(moment(lastShown), "days") > 0) {
          dispatch(updateUpgradeToPremiumDialog(true));
        }
      }
    } else if (getProfileError) {
      // Send to Sentry
      if (getProfileErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      } else {
        // Send to Sentry
        Sentry.captureMessage(
          "Search /me Error: " + JSON.stringify(getProfileErrorData)
        );
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | scrollYPosition
  useEffect(() => {
    dispatch(updateScrollYPosition(scrollYPosition));
  }, [scrollYPosition]);

  // Lifecycle | Check for update | searchScrollToTopPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (searchScrollToTopPassthrough) {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [searchScrollToTopPassthrough]);

  // Utility Functions
  const returnShouldAddHeight = () => {
    switch (selectedCategoryTab) {
      case "filtered":
        return filteredLists?.length > 0 ? false : true;
      case "recently_active":
        return recentlyActiveLists?.length > 0 ? false : true;
      case "nearest":
        return nearbyLists?.length > 0 ? false : true;
      case "newest":
        return newestLists?.length > 0 ? false : true;
      case "college":
        return collegeLists?.length > 0 ? false : true;
      case "premium":
        return premiumLists?.length > 0 ? false : true;
      default:
        return false;
    }
  };
  const handleScroll = () => {
    // Clear the previos timeout
    clearTimeout(timeoutId);

    // Set a new timeout to get scrollTop after 200 milliseconds of inactivity
    // This is to prevent the function from being called too many times in a short period of time
    timeoutId = setTimeout(() => {
      const currentPosition = scrollContainerRef?.current?.scrollTop;

      if (currentPosition) {
        dispatch(updateScrollYPosition(currentPosition));
      }
    }, 500);
  };

  return (
    <div
      id="search-page"
      className="search-page-container"
      ref={scrollContainerRef}
    >
      <div
        className={`max-width-container ${
          returnShouldAddHeight() ? "add-height" : ""
        }`}
      >
        <RestartJourneyNotice />

        {isMobile && <CategoryTab />}

        <div className="padding-container">
          {!isMobile && (
            <div className="desktop-search-filter">
              <SearchFilter />
            </div>
          )}

          <div className="search-body">
            {isMobile && selectedCategoryTab === "filtered" && (
              <div className="set-current-location-container">
                <FiltersSelected />
              </div>
            )}

            {/* In Review */}
            <ModerationStatus
              status={userConst.profileGeneralApprovalStatus.inReview}
              width={true}
            />

            {/* Rejected */}
            <ModerationStatus
              status={userConst.profileGeneralApprovalStatus.rejected}
              width={true}
            />

            <IncompleteProfile />

            <VerifyEmail />

            <Banner type={"search"} view={"carousel"} />

            {!isMobile && selectedCategoryTab === "filtered" && (
              <div className="set-current-location-container">
                <FiltersSelected />
              </div>
            )}

            <SearchCardContainer />
          </div>
        </div>
      </div>

      <UpgradeToPremiumDialog />
      <MissingTermsOfRelationshipDialog />
    </div>
  );
};

export default Search;
