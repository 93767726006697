// General
import "./banner.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetLivestreamingBannersQuery,
  useLazyGetLivestreamingPlayerFloatingBannersQuery,
  useLazyGetSearchBannersQuery,
  useLazyGetUserAioQuery,
  useLazyGetLivestreamingContestListQuery,
  useLazyGetPrivateCallsBannersQuery,
  useLazyGetGamingBannersQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateViewedMeBanners,
  updateProfileBanners,
  updateDiamondsWithdrawalInfoDialogBanner,
} from "../../../../redux/store/bannerStore";
import { updatePaymentTag } from "../../../../redux/store/paymentStore";
import {
  updateLeaderboardEventsDialog,
  updateOnboardingPrivateRoomBabyDialog,
  updateOnboardingPrivateRoomDaddyDialog,
  updateOnboardingSugarbookVerifiedDialog,
  updateUpdateTorDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const Banner = (props) => {
  const { type, view = "carousel" } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getLivestreamingBanners,
    {
      data: getLivestreamingBannersData,
      error: getLivestreamingBannersErrorData,
      isFetching: getLivestreamingBannersFetching,
      isLoading: getLivestreamingBannersLoading,
      isSuccess: getLivestreamingBannersSuccess,
      isError: getLivestreamingBannersError,
    },
  ] = useLazyGetLivestreamingBannersQuery();
  const [
    getLivestreamingPlayerFloatingBanners,
    {
      data: getLivestreamingPlayerFloatingBannersData,
      error: getLivestreamingPlayerFloatingBannersErrorData,
      isFetching: getLivestreamingPlayerFloatingBannersFetching,
      isLoading: getLivestreamingPlayerFloatingBannersLoading,
      isSuccess: getLivestreamingPlayerFloatingBannersSuccess,
      isError: getLivestreamingPlayerFloatingBannersError,
    },
  ] = useLazyGetLivestreamingPlayerFloatingBannersQuery();
  const [
    getSearchBanners,
    {
      data: getSearchBannersData,
      error: getSearchBannersErrorData,
      isFetching: getSearchBannersFetching,
      isLoading: getSearchBannersLoading,
      isSuccess: getSearchBannersSuccess,
      isError: getSearchBannersError,
    },
  ] = useLazyGetSearchBannersQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getLivestreamingContestList,
    {
      data: getLivestreamingContestListData,
      error: getLivestreamingContestListErrorData,
      isFetching: getLivestreamingContestListFetching,
      isLoading: getLivestreamingContestListLoading,
      isSuccess: getLivestreamingContestListSuccess,
      isError: getLivestreamingContestListError,
    },
  ] = useLazyGetLivestreamingContestListQuery();
  const [
    getPrivateCallsBanners,
    {
      data: getPrivateCallsBannersData,
      error: getPrivateCallsBannerErrorsData,
      isFetching: getPrivateCallsBannersFetching,
      isLoading: getPrivateCallsBannersLoading,
      isSuccess: getPrivateCallsBannersSuccess,
      isError: getPrivateCallsBannersError,
    },
  ] = useLazyGetPrivateCallsBannersQuery();
  const [
    getGamingBanners,
    {
      data: getGamingBannersData,
      error: getGamingBannersErrorData,
      isFetching: getGamingBannersFetching,
      isLoading: getGamingBannersLoading,
      isSuccess: getGamingBannersSuccess,
      isError: getGamingBannersError,
    },
  ] = useLazyGetGamingBannersQuery();

  // General variables
  const [timerObj, setTimerObj] = useState([]);
  const [expandBanner, setExpandBanner] = useState(false);

  // Render variables
  const [carouselView, setCarouselView] = useState([]);

  // Redux variables
  const viewedMeBanners = useSelector((state) => state.banner.viewedMeBanners);
  const profileBanners = useSelector((state) => state.banner.profileBanners);
  const diamondsWithdrawalInfoDialogBanner = useSelector(
    (state) => state.banner.diamondsWithdrawalInfoDialogBanner
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getUserAio(null, true);
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        if (timerObj) {
          timerObj.map((count) => {
            clearInterval(count.timerInterval);
          });
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | Livestream Banner API Response
  useEffect(() => {
    if (getLivestreamingBannersFetching || getLivestreamingBannersLoading) {
    } else if (getLivestreamingBannersSuccess) {
      if (getLivestreamingBannersData?.status === 0) {
        if (getLivestreamingBannersData?.data?.banners) {
          organizeLivestreamTimerObj(
            getLivestreamingBannersData?.data?.banners
          );

          if (view === "carousel") {
            setCarouselView(
              <div className="carousel-container border-radius">
                <swiper-container
                  class="swiper-container border-radius"
                  navigation="false"
                  pagination="true"
                  scrollbar="false"
                  // loop="true"
                  speed="300"
                  autoplay-delay="2500"
                >
                  {getLivestreamingBannersData?.data?.banners?.map(
                    (banner, index) => (
                      <swiper-slide
                        class="banner-container"
                        onClick={() => onOpenLink(banner)}
                        key={index}
                      >
                        <img className="banner-image" src={banner?.asset_url} />
                        <div
                          className="livestream-timer-container"
                          style={{ color: banner?.countdown_font_color }}
                        ></div>
                      </swiper-slide>
                    )
                  )}
                </swiper-container>
              </div>
            );
          } else if (view === "stack") {
            setCarouselView(
              <div className="stack-container">
                <div className="leaderboard-banner-container border-radius">
                  {getLivestreamingBannersData?.data?.banners?.map(
                    (banner, index) => (
                      <div
                        className="banner-container"
                        onClick={() => onOpenLink(banner)}
                        key={index}
                      >
                        <img className="banner-image" src={banner?.asset_url} />
                        <div
                          className="livestream-timer-container"
                          style={{ color: banner?.countdown_font_color }}
                        ></div>
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          }
        }
      }
    } else if (getLivestreamingBannersError) {
      if (getLivestreamingBannersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingBannersFetching,
    getLivestreamingBannersLoading,
    getLivestreamingBannersSuccess,
    getLivestreamingBannersError,
  ]);

  // Lifecycle | Check for update | Livestream Player Floating Banner API Response
  useEffect(() => {
    if (
      getLivestreamingPlayerFloatingBannersFetching ||
      getLivestreamingPlayerFloatingBannersLoading
    ) {
    } else if (getLivestreamingPlayerFloatingBannersSuccess) {
      if (
        getLivestreamingPlayerFloatingBannersData?.status === 0 &&
        getLivestreamingPlayerFloatingBannersData?.data?.banners?.length > 0
      ) {
        organizeLivestreamFloatingTimerObj(
          getLivestreamingPlayerFloatingBannersData?.data?.banners
        );

        setCarouselView(
          <div className="expandable-floating-container">
            <div
              className={`base-container default-floating-banner-gradient ${
                getLivestreamingPlayerFloatingBannersData?.data?.banners[0]
                  ?.template
              } ${expandBanner ? "expand-banner" : ""}`}
            >
              <div className="banner-image-container" onClick={onExpandBanner}>
                <img
                  className="banner-image"
                  src={
                    getLivestreamingPlayerFloatingBannersData?.data?.banners[0]
                      ?.asset_url
                  }
                />
              </div>

              {!expandBanner && (
                <div className="livestream-floating-short-timer-container"></div>
              )}

              {expandBanner && (
                <div
                  className={`banner-description-container ${
                    expandBanner ? "expanded" : ""
                  }`}
                  onClick={onOpenEventsLeaderboard}
                >
                  <div
                    className="banner-description-label"
                    style={{
                      color:
                        getLivestreamingPlayerFloatingBannersData?.data
                          ?.banners[0]?.title_font_color,
                    }}
                  >
                    {
                      getLivestreamingPlayerFloatingBannersData?.data
                        ?.banners[0]?.text
                    }
                  </div>

                  <div
                    className="livestream-floating-timer-container"
                    style={{
                      color:
                        getLivestreamingPlayerFloatingBannersData?.data
                          ?.banners[0]?.countdown_font_color,
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        );
      }
    } else if (getLivestreamingPlayerFloatingBannersError) {
    }
  }, [
    getLivestreamingPlayerFloatingBannersFetching,
    getLivestreamingPlayerFloatingBannersLoading,
    getLivestreamingPlayerFloatingBannersSuccess,
    getLivestreamingPlayerFloatingBannersError,
    expandBanner,
  ]);

  // Lifecycle | Check for update | Search Banners API Response
  useEffect(() => {
    if (getSearchBannersLoading) {
    } else if (getSearchBannersSuccess) {
      if (getSearchBannersData?.success === "true") {
        setCarouselView(
          <div className="carousel-container border-radius">
            <swiper-container
              class="swiper-container"
              navigation="false"
              pagination="true"
              scrollbar="false"
              // loop="true"
              speed="300"
              autoplay-delay="2500"
            >
              {getSearchBannersData?.banner?.map((banner, index) => (
                <swiper-slide
                  class="banner-container"
                  onClick={() => onOpenLink(banner)}
                  key={index}
                >
                  <img
                    className="banner-image border-radius"
                    src={banner?.image}
                  />
                  <div
                    className="search-timer-container"
                    style={{
                      color: banner?.countdown_data?.countdown_font_color,
                    }}
                  ></div>
                </swiper-slide>
              ))}
            </swiper-container>
          </div>
        );

        organizeSearchTimerObj(getSearchBannersData?.banner);
      }
    } else if (getSearchBannersError) {
      if (getSearchBannersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getSearchBannersFetching,
    getSearchBannersLoading,
    getSearchBannersSuccess,
    getSearchBannersError,
  ]);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioLoading) {
    } else if (getUserAioSuccess && getProfileSuccess) {
      if (getUserAioData?.status === 1) {
        let banners = getUserAioData?.data?.promotions?.p_20210818?.graphics;
        let cashoutBanner = getUserAioData?.data?.urls?.cash_out_banner;

        // Organize Viewed Me Banners
        if (viewedMeBanners?.length <= 0) {
          if (banners?.length > 0) {
            for (let i = 0; i < banners?.length; i++) {
              if (banners[i]?.view === "viewed_me") {
                if (getProfileData?.data?.membership_type === "free") {
                  if (banners[i]?.constant?.includes("fifty_percent_off")) {
                    dispatch(updateViewedMeBanners(banners[i]));
                  }
                } else {
                  if (banners[i]?.constant?.includes("twenty_percent_off")) {
                    dispatch(updateViewedMeBanners(banners[i]));
                  }
                }
              }
            }
          }
        }

        // Organize Profile Banners
        if (profileBanners?.length <= 0) {
          if (banners?.length > 0) {
            for (let i = 0; i < banners?.length; i++) {
              if (banners[i]?.view === "profile") {
                if (banners[i]?.type === "banner") {
                  if (getProfileData?.data?.membership_type === "free") {
                    if (banners[i]?.constant?.includes("fifty_percent_off")) {
                      dispatch(updateProfileBanners(banners[i]));
                    }
                  } else {
                    if (banners[i]?.constant?.includes("twenty_percent_off")) {
                      dispatch(updateProfileBanners(banners[i]));
                    }
                  }
                }
              }
            }
          }
        }

        // Organize Diamonds Withdrawal Banners
        if (diamondsWithdrawalInfoDialogBanner?.length <= 0) {
          dispatch(updateDiamondsWithdrawalInfoDialogBanner(cashoutBanner));
        }
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getProfileSuccess,
    getUserAioError,
  ]);

  // Lifecycle | Check for update | Livestreaming Contest List API Response
  useEffect(() => {
    if (
      getLivestreamingContestListFetching ||
      getLivestreamingContestListLoading
    ) {
    } else if (getLivestreamingContestListSuccess) {
      if (getLivestreamingContestListData?.status === 1) {
        if (getLivestreamingContestListData?.data?.results?.length > 0) {
          if (
            getLivestreamingContestListData?.data?.results[0].graphics?.length >
            0
          ) {
            for (
              let i = 0;
              i <
              getLivestreamingContestListData?.data?.results[0].graphics
                ?.length;
              i++
            ) {
              if (
                getLivestreamingContestListData?.data?.results[0].graphics[
                  i
                ].type.includes("coins")
              ) {
                setCarouselView(
                  <div className="stack-container">
                    <div className="coin-shop-banner-container">
                      <img
                        className="coin-shop-banner"
                        src={
                          getLivestreamingContestListData?.data?.results[0]
                            .graphics[i]?.asset_url
                        }
                      />
                    </div>
                  </div>
                );
              }
            }
          }
        }
      }
    } else if (getLivestreamingContestListError) {
      if (getLivestreamingBannersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingContestListFetching,
    getLivestreamingContestListLoading,
    getLivestreamingContestListSuccess,
    getLivestreamingContestListError,
  ]);

  // Lifecycle | Check for update | Private Call Banner API Response
  useEffect(() => {
    if (getPrivateCallsBannersFetching || getPrivateCallsBannersLoading) {
    } else if (getPrivateCallsBannersSuccess) {
      if (getPrivateCallsBannersData?.status === 0) {
        if (getPrivateCallsBannersData?.data?.contest_banners?.length > 0) {
          organizePrivateCallTimerObj(
            getPrivateCallsBannersData?.data?.contest_banners
          );

          if (view === "carousel") {
            setCarouselView(
              <div className="carousel-container border-radius">
                <swiper-container
                  class="swiper-container border-radius"
                  navigation="false"
                  pagination={
                    getPrivateCallsBannersData?.data?.contest_banners?.length >
                    1
                      ? "true"
                      : "false"
                  }
                  scrollbar="false"
                  loop={
                    getPrivateCallsBannersData?.data?.contest_banners?.length >
                    1
                      ? "true"
                      : "false"
                  }
                  speed="300"
                  autoplay-delay="2500"
                >
                  {getPrivateCallsBannersData?.data?.contest_banners?.map(
                    (banner, index) => (
                      <swiper-slide
                        class="banner-container"
                        onClick={() => onOpenLink(banner)}
                        key={index}
                      >
                        <img className="banner-image" src={banner?.asset_url} />
                        <div
                          className="private-call-timer-container"
                          style={{ color: banner?.countdown_font_color }}
                        ></div>
                      </swiper-slide>
                    )
                  )}
                </swiper-container>
              </div>
            );
          } else if (view === "stack") {
            setCarouselView(
              <div className="stack-container">
                <div className="leaderboard-banner-container border-radius">
                  {getPrivateCallsBannersData?.data?.contest_banners?.map(
                    (banner, index) => (
                      <div
                        className="banner-container"
                        onClick={() => onOpenLink(banner)}
                        key={index}
                      >
                        <img className="banner-image" src={banner?.asset_url} />
                        <div
                          className="private-call-timer-container"
                          style={{ color: banner?.countdown_font_color }}
                        ></div>
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          }
        }
      }
    } else if (getPrivateCallsBannersError) {
      if (getPrivateCallsBannerErrorsData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallsBannersFetching,
    getPrivateCallsBannersLoading,
    getPrivateCallsBannersSuccess,
    getPrivateCallsBannersError,
  ]);

  // Lifecycle | Check for update | Gaming Banner API Response
  useEffect(() => {
    if (getGamingBannersFetching || getGamingBannersLoading) {
    } else if (getGamingBannersSuccess) {
      if (getGamingBannersData?.status === 1) {
        if (getGamingBannersData?.data?.banners?.length > 0) {
          organizeGamingTimerObj(getGamingBannersData?.data?.banners);

          if (view === "carousel") {
            setCarouselView(
              <div className="carousel-container border-radius">
                <swiper-container
                  class="swiper-container border-radius"
                  navigation="false"
                  pagination={
                    getGamingBannersData?.data?.banners?.length > 1
                      ? "true"
                      : "false"
                  }
                  scrollbar="false"
                  loop={
                    getGamingBannersData?.data?.banners?.length > 1
                      ? "true"
                      : "false"
                  }
                  speed="300"
                  autoplay-delay="2500"
                >
                  {getGamingBannersData?.data?.banners?.map((banner, index) => (
                    <swiper-slide
                      class="banner-container"
                      onClick={() => onOpenLink(banner)}
                      key={index}
                    >
                      <img className="banner-image" src={banner?.asset_url} />
                      <div
                        className="gaming-timer-container"
                        style={{ color: banner?.countdown_font_color }}
                      ></div>
                    </swiper-slide>
                  ))}
                </swiper-container>
              </div>
            );
          } else if (view === "stack") {
            setCarouselView(
              <div className="stack-container">
                <div className="leaderboard-banner-container border-radius">
                  {getGamingBannersData?.data?.banners?.map((banner, index) => (
                    <div
                      className="banner-container"
                      onClick={() => onOpenLink(banner)}
                      key={index}
                    >
                      <img className="banner-image" src={banner?.asset_url} />
                      <div
                        className="gaming-timer-container"
                        style={{ color: banner?.countdown_font_color }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            );
          }
        }
      }
    } else if (getGamingBannersError) {
    }
  }, [
    getGamingBannersFetching,
    getGamingBannersLoading,
    getGamingBannersSuccess,
    getGamingBannersError,
  ]);

  // Lifecycle | Check for update | type
  useEffect(() => {
    if (!type) return;

    switch (type) {
      case "leaderboard-livestreaming-normal":
      case "leaderboard-livestreaming-tier-1":
        getLivestreamingBanners(`?tier=1`, true);
        break;
      case "leaderboard-livestreaming-tier-2":
        getLivestreamingBanners(`?tier=2`, true);
        break;
      case "leaderboard-livestreaming-tier-3":
        getLivestreamingBanners(`?tier=3`, true);
        break;
      case "leaderboard-livestreaming-pk":
        getLivestreamingBanners("?pk=true", true);
        break;
      case "leaderboard-private-call-normal":
        getPrivateCallsBanners(null, true);
        break;
      case "leaderboard-private-call-events":
        getPrivateCallsBanners(null, true);
        break;
      case "leaderboard-casino-normal":
      case "live-gaming":
        getGamingBanners(null, true);
        break;
      case "live":
        getLivestreamingBanners(null, true);
        break;
      case "livestream":
        getLivestreamingPlayerFloatingBanners(null, true);
        break;
      case "video-call":
      case "search":
        getSearchBanners(null, true);
        break;
      case "inbox":
      case "interests":
        if (!viewedMeBanners) return;

        organizeUserAioTimerObj(viewedMeBanners);

        setCarouselView(
          <div className="carousel-container">
            <swiper-container
              class="swiper-container"
              navigation="false"
              pagination={viewedMeBanners?.length > 1 ? "true" : "false"}
              scrollbar="false"
              loop={viewedMeBanners?.length > 1 ? "true" : "false"}
              speed="300"
              autoplay-delay="2500"
            >
              {viewedMeBanners?.map((banner, index) => (
                <swiper-slide
                  class="banner-container"
                  onClick={() => onOpenLink(banner)}
                  key={index}
                >
                  <img className="banner-image" src={banner?.asset_url} />
                  <div className="user-aio-timer-container"></div>
                </swiper-slide>
              ))}
            </swiper-container>
          </div>
        );
        break;
      case "profile-overview":
      case "video-call-settings":
        if (!profileBanners) return;

        organizeUserAioTimerObj(profileBanners);

        setCarouselView(
          <div className="carousel-container border-radius">
            <swiper-container
              class="swiper-container border-radius"
              navigation="false"
              pagination={profileBanners?.length > 1 ? "true" : "false"}
              scrollbar="false"
              loop={profileBanners?.length > 1 ? "true" : "false"}
              speed="300"
              autoplay-delay="2500"
            >
              {profileBanners?.map((banner, index) => (
                <swiper-slide
                  class="banner-container"
                  onClick={() => onOpenLink(banner)}
                  key={index}
                >
                  <img className="banner-image" src={banner?.asset_url} />
                  <div className="user-aio-timer-container"></div>
                </swiper-slide>
              ))}
            </swiper-container>
          </div>
        );
        break;
      case "coin-shop":
        getLivestreamingContestList(null, true);
        break;
      case "diamonds-withdrawal-dialog":
        if (!diamondsWithdrawalInfoDialogBanner) return;

        setCarouselView(
          <div className="carousel-container border-radius">
            <swiper-container
              class="swiper-container border-radius"
              navigation="false"
              pagination={
                diamondsWithdrawalInfoDialogBanner?.length > 1
                  ? "true"
                  : "false"
              }
              scrollbar="false"
              loop={
                diamondsWithdrawalInfoDialogBanner?.length > 1
                  ? "true"
                  : "false"
              }
              speed="300"
              autoplay-delay="2500"
            >
              {diamondsWithdrawalInfoDialogBanner?.map((banner, index) => (
                <swiper-slide
                  class="banner-container"
                  onClick={() => onOpenLink(banner)}
                  key={index}
                >
                  <img className="banner-image" src={banner?.asset} />
                </swiper-slide>
              ))}
            </swiper-container>
          </div>
        );
        break;
      default:
        break;
    }
  }, [type, viewedMeBanners, profileBanners]);

  // Events Handler | Button
  const onOpenEventsLeaderboard = () => {
    dispatch(updateLeaderboardEventsDialog(true));
  };
  const onExpandBanner = () => {
    setExpandBanner(!expandBanner);
  };
  const onOpenLink = (banner) => {
    switch (type) {
      case "leaderboard-livestreaming-normal":
      case "leaderboard-livestreaming-tier-1":
        if (banner?.cta === "live") {
          onNavigate(routeConst.live.path);
        } else if (banner.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.1-44.1.1-${banner?.reference}`,
          },
        });
        break;
      case "leaderboard-livestreaming-tier-2":
        if (banner?.cta === "live") {
          onNavigate(routeConst.live.path);
        } else if (banner.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.1-44.1.1-${banner?.reference}`,
          },
        });
        break;
      case "leaderboard-livestreaming-tier-3":
        if (banner?.cta === "live") {
          onNavigate(routeConst.live.path);
        } else if (banner.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.1-44.1.1-${banner?.reference}`,
          },
        });
        break;
      case "leaderboard-livestreaming-pk":
        if (banner?.cta === "live") {
          onNavigate(routeConst.live.path);
        } else if (banner.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.1-44.1.1-${banner?.reference}`,
          },
        });
        break;
      case "leaderboard-private-call-normal":
        if (banner?.cta === "live") {
          onNavigate(routeConst.live.path);
        } else if (banner.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.1-44.1.2-${banner?.reference}`,
          },
        });
        break;
      case "leaderboard-private-call-events":
        if (banner?.cta === "live") {
          onNavigate(routeConst.live.path);
        } else if (banner.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.1-44.1.2-44.1.2.1-${banner?.reference}`,
          },
        });
        break;
      case "leaderboard-casino-normal":
      case "live-gaming":
        if (banner?.cta === "game_entry") {
          onNavigate(
            `${routeConst.games.casino.path}`,
            false,
            `?game_id=${banner?.game_id}&game_title=${banner?.title}`
          );
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.1-44.1.3-${banner?.game_id}`,
          },
        });
        break;
      case "live":
        if (banner?.type === "game_entry") {
          onNavigate(routeConst.games.casino.path);
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.2-${banner?.reference}`,
          },
        });
        break;
      case "video-call":
      case "search":
        if (banner?.name === "video_call_landing") {
          dispatch(updateOnboardingPrivateRoomDaddyDialog(true));
        } else if (banner?.name === "p_20210818.premium") {
          dispatch(updatePaymentTag("pwa-search-pivot-banner"));
          onNavigate(routeConst.upgrade.path);
        } else if (banner?.name === "p_20210818.coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.name.includes("contest.")) {
          onNavigate(routeConst.live.path);
        } else if (banner?.name === "tor") {
          dispatch(updateUpdateTorDialog(true));
        } else if (banner?.name === "verified_profile") {
          dispatch(updateOnboardingSugarbookVerifiedDialog(true));
        } else if (banner?.link) {
          window.open(banner?.link, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.3-${banner?.unique_identifier}`,
          },
        });
        break;
      case "inbox":
      case "interests":
        if (banner?.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.cta === "premium") {
          dispatch(updatePaymentTag("pwa-interest-pivot-banner"));
          onNavigate(routeConst.upgrade.path);
        } else if (banner?.cta === "verified_profile") {
          dispatch(updateOnboardingSugarbookVerifiedDialog(true));
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.4-${banner?.constant}`,
          },
        });
        break;
      case "profile-overview":
      case "video-call-settings":
        if (banner?.cta === "coin_shop") {
          onNavigate(routeConst.profile.wallet.path);
        } else if (banner?.cta === "premium") {
          dispatch(updatePaymentTag("pwa-video-call-settings-banner"));
          onNavigate(routeConst.upgrade.path);
        } else if (banner?.cta === "private_call_recruitment_landing") {
          dispatch(updateOnboardingPrivateRoomBabyDialog(true));
        } else if (banner?.cta === "tor") {
          dispatch(updateUpdateTorDialog(true));
        } else if (banner?.cta === "verified_profile") {
          dispatch(updateOnboardingSugarbookVerifiedDialog(true));
        } else if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.5-${banner?.constant}`,
          },
        });
        break;
      case "diamonds-withdrawal-dialog":
        if (banner?.url) {
          window.open(banner?.url, "_blank");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: `PWA-44-44.6`,
          },
        });
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const organizeLivestreamFloatingTimerObj = (banners) => {
    if (timerObj?.length > 0) return;

    const timerObjects = banners?.map((banner, index) => {
      const hasTimer = banner?.is_countdown_required;
      const endEpoch = banner?.countdown_end_at_epoch;
      let timerInterval = null;
      let countdown = null;
      let countdownShort = null;

      if (hasTimer) {
        const end = moment.unix(endEpoch);
        timerInterval = setInterval(() => {
          const now = moment();
          const diff = moment.duration(end.diff(now));

          if (diff.asSeconds() <= 0) {
            clearInterval(timerInterval);
            countdown = t("common.contest_ended");
          } else {
            const days = Math.floor(diff.asDays());
            const hrs = diff.hours();
            const mnts = diff.minutes();
            const seconds = diff.seconds();

            countdown = `Time Left: ${days} DAYS ${hrs}:${mnts
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

            countdownShort = `${days} DAYS ${hrs}:${mnts
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
          }

          const container = document.getElementsByClassName(
            "livestream-floating-timer-container"
          )[index];
          const containerShort = document.getElementsByClassName(
            "livestream-floating-short-timer-container"
          )[index];
          if (container) {
            container.innerHTML = countdown;
          }
          if (containerShort) {
            containerShort.innerHTML = countdownShort;
          }
        }, 1000);
      }

      return {
        index,
        hasTimer,
        endEpoch,
        timerInterval,
        countdown,
      };
    });

    setTimerObj(timerObjects || []);
  };
  const organizeLivestreamTimerObj = (banners) => {
    const newTimerObj = banners?.map((banner, index) => {
      const hasTimer = banner?.is_countdown_required;
      const startEpoch = banner?.countdown_start_at_epoch;
      const endEpoch = banner?.countdown_end_at_epoch;
      let countdown = null;

      if (!hasTimer) {
        return {
          index,
          hasTimer,
          endEpoch,
          timerInterval: null,
          countdown,
        };
      }

      const timerInterval = setInterval(() => {
        const today = moment();
        const start = moment.unix(startEpoch);
        const end = moment.unix(endEpoch);
        const readableStartDate = getReadableDateFromEpoch(startEpoch);
        const readableEndDate = getReadableDateFromEpoch(endEpoch);

        // Clear Interval if Epoch is less than 0
        if (endEpoch < 0) {
          clearInterval(timerInterval);
          return;
        }

        // Set Countdown
        if (today.isAfter(end)) {
          countdown = t("common.contest_ended");
          clearInterval(timerInterval);
        } else {
          if (today.isBefore(start)) {
            countdown = `${t("common.starts_in")} ${t("common.n_day", {
              n: readableStartDate.days,
            })} ${readableStartDate.hrs}:${
              readableStartDate.mnts > 9
                ? readableStartDate.mnts
                : `0${readableStartDate.mnts}`
            }:${
              readableStartDate.seconds > 9
                ? readableStartDate.seconds
                : `0${readableStartDate.seconds}`
            }`;
          } else {
            countdown = `${t("common.ends_in")} ${t("common.n_day", {
              n: readableEndDate.days,
            })} ${readableEndDate.hrs}:${
              readableEndDate.mnts > 9
                ? readableEndDate.mnts
                : `0${readableEndDate.mnts}`
            }:${
              readableEndDate.seconds > 9
                ? readableEndDate.seconds
                : `0${readableEndDate.seconds}`
            }`;
          }
        }

        // Set Countdown to DOM
        const timerContainer = document.getElementsByClassName(
          "livestream-timer-container"
        )[index];
        if (timerContainer) {
          timerContainer.innerHTML = countdown;
        }
      }, 1000);

      return {
        index,
        hasTimer,
        endEpoch,
        timerInterval,
        countdown,
      };
    });

    setTimerObj(newTimerObj);
  };
  const organizePrivateCallTimerObj = (banners) => {
    const newTimerObj = banners?.map((banner, index) => {
      const hasTimer = banner?.is_countdown_required;
      const startEpoch = banner?.countdown_start_at_epoch;
      const endEpoch = banner?.countdown_end_at_epoch;
      let countdown = null;

      if (!hasTimer) {
        return {
          index,
          hasTimer,
          startEpoch,
          endEpoch,
          timerInterval: null,
          countdown,
        };
      }

      const timerInterval = setInterval(() => {
        const today = moment();
        const start = moment.unix(startEpoch);
        const end = moment.unix(endEpoch);
        const readableStartDate = getReadableDateFromEpoch(startEpoch);
        const readableEndDate = getReadableDateFromEpoch(endEpoch);

        // Clear Interval if Epoch is less than 0
        if (endEpoch < 0) {
          clearInterval(timerInterval);
          return;
        }

        // Set Countdown
        if (today.isAfter(end)) {
          countdown = t("common.contest_ended");
          clearInterval(timerInterval);
        } else {
          if (today.isBefore(start)) {
            countdown = `${t("common.starts_in")} ${t("common.n_day", {
              n: readableStartDate.days,
            })} ${readableStartDate.hrs}:${
              readableStartDate.mnts > 9
                ? readableStartDate.mnts
                : `0${readableStartDate.mnts}`
            }:${
              readableStartDate.seconds > 9
                ? readableStartDate.seconds
                : `0${readableStartDate.seconds}`
            }`;
          } else {
            countdown = `${t("common.ends_in")} ${t("common.n_day", {
              n: readableEndDate.days,
            })} ${readableEndDate.hrs}:${
              readableEndDate.mnts > 9
                ? readableEndDate.mnts
                : `0${readableEndDate.mnts}`
            }:${
              readableEndDate.seconds > 9
                ? readableEndDate.seconds
                : `0${readableEndDate.seconds}`
            }`;
          }
        }

        // Set Countdown to DOM
        const timerContainer = document.getElementsByClassName(
          "private-call-timer-container"
        )[index];
        if (timerContainer) {
          timerContainer.innerHTML = countdown;
        }
      }, 1000);

      return {
        index,
        hasTimer,
        endEpoch,
        timerInterval,
        countdown,
      };
    });

    setTimerObj(newTimerObj);
  };
  const organizeGamingTimerObj = (banners) => {
    const newTimerObj = banners?.map((banner, index) => {
      const hasTimer = banner?.is_countdown_required;
      const startEpoch = banner?.countdown_start_at_epoch;
      const endEpoch = banner?.countdown_end_at_epoch;
      let countdown = null;

      if (!hasTimer) {
        return {
          index,
          hasTimer,
          startEpoch,
          endEpoch,
          timerInterval: null,
          countdown,
        };
      }

      const timerInterval = setInterval(() => {
        const today = moment();
        const start = moment.unix(startEpoch);
        const end = moment.unix(endEpoch);
        const readableStartDate = getReadableDateFromEpoch(startEpoch);
        const readableEndDate = getReadableDateFromEpoch(endEpoch);

        // Clear Interval if Epoch is less than 0
        if (endEpoch < 0) {
          clearInterval(timerInterval);
          return;
        }

        // Set Countdown
        if (today.isBefore(start)) {
          countdown = `${t("common.starts_in")} ${t("common.n_day", {
            n: readableStartDate.days,
          })} ${readableStartDate.hrs}:${
            readableStartDate.mnts > 9
              ? readableStartDate.mnts
              : `0${readableStartDate.mnts}`
          }:${
            readableStartDate.seconds > 9
              ? readableStartDate.seconds
              : `0${readableStartDate.seconds}`
          }`;
        } else if (today.isAfter(end)) {
          countdown = t("common.contest_ended");
          clearInterval(timerInterval);
        } else {
          countdown = `${t("common.ends_in")} ${t("common.n_day", {
            n: readableEndDate.days,
          })} ${readableEndDate.hrs}:${
            readableEndDate.mnts > 9
              ? readableEndDate.mnts
              : `0${readableEndDate.mnts}`
          }:${
            readableEndDate.seconds > 9
              ? readableEndDate.seconds
              : `0${readableEndDate.seconds}`
          }`;
        }

        // Set Countdown to DOM
        const timerContainer = document.getElementsByClassName(
          "private-call-timer-container"
        )[index];
        if (timerContainer) {
          timerContainer.innerHTML = countdown;
        }
      }, 1000);

      return {
        index,
        hasTimer,
        endEpoch,
        timerInterval,
        countdown,
      };
    });

    setTimerObj(newTimerObj);
  };
  const organizeSearchTimerObj = (banners) => {
    let newArr = [];

    banners.map((banner, index) => {
      newArr.push({
        index: index,
        hasTimer: banner?.countdown_data ? true : false,
        startEpoch: banner?.countdown_data?.countdown_start_at_epoch,
        endEpoch: banner?.countdown_data?.countdown_end_at_epoch,
        label: banner?.countdown_data?.copies,
        timerInterval: null,
        countdown: null,
      });
    });

    newArr.map((item, index) => {
      if (item.hasTimer) {
        let today = moment();
        let start = moment.unix(item.startEpoch);
        let end = moment.unix(item.endEpoch);

        item.timerInterval = setInterval(() => {
          let readableStartDate = getReadableDateFromEpoch(item.startEpoch);
          let readableEndDate = getReadableDateFromEpoch(item.endEpoch);

          // Clear Interval if Epoch is less than 0
          if (item.endEpoch < 0) {
            clearInterval(item.timerInterval);
          }

          // Set Countdown
          if (today.isBefore(start)) {
            item.countdown = `${t("common.starts_in")} ${t("common.n_day", {
              n: readableStartDate.days,
            })} ${readableStartDate.hrs}:${
              readableStartDate.mnts > 9
                ? readableStartDate.mnts
                : `0${readableStartDate.mnts}`
            }:${
              readableStartDate.seconds > 9
                ? readableStartDate.seconds
                : `0${readableStartDate.seconds}`
            }`;
          } else if (today.isAfter(end)) {
            item.countdown = item?.label;
            clearInterval(item.timerInterval);
          } else {
            item.countdown = `${t("common.ends_in")} ${t("common.n_day", {
              n: readableEndDate.days,
            })} ${readableEndDate.hrs}:${
              readableEndDate.mnts > 9
                ? readableEndDate.mnts
                : `0${readableEndDate.mnts}`
            }:${
              readableEndDate.seconds > 9
                ? readableEndDate.seconds
                : `0${readableEndDate.seconds}`
            }`;
          }

          // Set Countdown to DOM
          // Reason we use DOM injection is because Carousel does not update after it is initialized
          const timerContainers = document.getElementsByClassName(
            "search-timer-container"
          );
          if (
            timerContainers.length > index &&
            item &&
            item.countdown !== undefined
          ) {
            timerContainers[index].innerHTML = item.countdown;
          }
        }, 1000);
      }
    });

    setTimerObj(newArr);
  };
  const organizeUserAioTimerObj = (banners) => {
    let newArr = [];

    banners.map((banner, index) => {
      newArr.push({
        index: index,
        hasTimer: banner?.countdown_data ? true : false,
        endEpoch: banner?.countdown_data?.countdown_end_at_epoch,
        label: banner?.countdown_data?.copies,
        timerInterval: null,
        countdown: null,
      });
    });

    newArr.map((item, index) => {
      if (item.hasTimer) {
        let today = moment();
        let end = moment.unix(item.endEpoch);

        item.timerInterval = setInterval(() => {
          let readableDate = getReadableDateFromEpoch(item.endEpoch);

          // Clear Interval if Epoch is less than 0
          if (item.endEpoch < 0) {
            clearInterval(item.timerInterval);
          }

          // Set Countdown
          if (today.isAfter(end)) {
            item.countdown = item?.label;
            clearInterval(item.timerInterval);
          } else {
            item.countdown = `${t("common.ends_in")} ${t("common.n_day", {
              n: readableDate.days,
            })} ${readableDate.hrs}:${
              readableDate.mnts > 9
                ? readableDate.mnts
                : `0${readableDate.mnts}`
            }:${
              readableDate.seconds > 9
                ? readableDate.seconds
                : `0${readableDate.seconds}`
            }`;
          }

          // Set Countdown to DOM
          // Reason we use DOM injection is because Carousel does not update after it is initialized
          const timerContainers = document.getElementsByClassName(
            "search-timer-container"
          );
          if (
            timerContainers.length > index &&
            item &&
            item.countdown !== undefined
          ) {
            timerContainers[index].innerHTML = item.countdown;
          }
        }, 1000);
      }
    });

    setTimerObj(newArr);
  };
  const getReadableDateFromEpoch = (epoch) => {
    // Convert Epoch to Seconds
    let epochToSeconds = epoch - moment().unix();

    // Convert Seconds to Days, Hours, Minutes, Seconds
    const days = Math.floor(epochToSeconds / (3600 * 24));
    epochToSeconds -= days * 3600 * 24;
    const hrs = Math.floor(epochToSeconds / 3600);
    epochToSeconds -= hrs * 3600;
    const mnts = Math.floor(epochToSeconds / 60);
    epochToSeconds -= mnts * 60;
    const seconds = epochToSeconds;

    return { days, hrs, mnts, seconds };
  };

  return <div id="banner-subcomponent">{carouselView}</div>;
};

export default Banner;
