// General
import "./co-anchor-card.scss";
import { Link } from "react-router-dom";
// Static Data
import routeConst from "../../../../const/routeConst";
import userCardConst from "../../../../const/userCardConst";
import userConst from "../../../../const/userConst";
import searchConst from "../../../../const/searchConst";
// Redux
import { useDispatch } from "react-redux";
import { updateVerifiedCallerDescriptionDialog } from "../../../../redux/store/dialogStore";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateProfileAchievementBadge,
  updateTorTags,
  updateDatingStyles,
  updateAboutMe,
  updateLookingFor,
  updateTagline,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,

  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,

  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoAbleToRequest,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
  updateProfileStatus,
} from "../../../../redux/store/userCardStore";
import {
  updateChannelId,
  updateLivestreamerId,
  updateLivestreamerIdInt,
  updateLivestreamerProfilePhoto,
  updateLivestreamerAchievements,
  updateLivestreamerUsername,
  updateLivestreamerLevellingBadge,
  updateLivestreamerFollowers,
  updateLivestreamerViewers,
  updateLivestreamerDiamonds,
  updateLivestreamerIsFollowing,
} from "../../../../redux/store/livestreamingStore";
import { updateWarningToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import VisibilityIcon from "@mui/icons-material/Visibility";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../custom-avatar/custom-avatar";

const CoAnchorCard = (props) => {
  const {
    // Settings
    cardView = searchConst.userCardDisplay.grid, // list, grid

    // User View
    id,
    idInt,
    username,
    age,
    gender,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    datingStyle,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    tagline,
    annualIncome,
    networth,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    withinDistanceInKm,
    // About Me
    personalityTraits,
    personalityType,
    starSign,
    dominantOrSubmissive,
    fitnessLevel,
    hobbies,
    // User Utility
    profileStatus,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
    interest,
    // Livestream
    channelId,
    livestreamerId,
    followerCount,
    viewerCount,
    diamondCount,
    isFollowing,
    // Card Utility
    cardStatus,

    // Co Anchor Utility
    hostProfilePhoto,
    coHostProfilePhoto,
    hasPk,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onUserClick = (type) => {
    if (cardStatus === userCardConst.cardStatus.default) {
      switch (profileStatus) {
        case userCardConst.userProfileStatus.inPrivateAvailable:
        case userCardConst.userProfileStatus.inPrivateBusy:
        case userCardConst.userProfileStatus.videoCall:
        case userCardConst.userProfileStatus.normal:
        case userCardConst.userProfileStatus.live:
        case userCardConst.userProfileStatus.coAnchor:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-S4-4.1-Button",
            },
          });

          // User View
          dispatch(updateId(id));
          dispatch(updateIdInt(idInt));
          dispatch(updateUsername(username));
          dispatch(updateAge(age));
          dispatch(updateGender(gender));
          dispatch(updateLocation(location));
          dispatch(updateMembershipType(membershipType));
          dispatch(updateVerifiedProfile(verifiedProfile));
          dispatch(updateVerifiedCaller(verifiedCaller));
          dispatch(updateLevellingBadge(levellingBadge));
          dispatch(
            updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
          );
          dispatch(updateProfilePhoto(profilePhoto));
          dispatch(updateProfileDecorations(profileDecorations));
          dispatch(updatePublicPhotos(publicPhotos));
          dispatch(updatePrivatePhotos(privatePhotos));
          dispatch(updateProfileAchievementBadge(profileAchievementBadge));
          dispatch(updateTorTags(torTags));
          dispatch(updateDatingStyles(datingStyle));
          // dispatch(updateAboutMe(aboutMe));
          dispatch(updateTagline(tagline));
          dispatch(updateAnnualIncome(annualIncome));
          dispatch(updateNetworth(networth));
          dispatch(updateOccupation(occupation));
          dispatch(updateLifestyle(lifestyle));
          dispatch(updateEducation(education));
          dispatch(updateRelationship(relationship));
          dispatch(updateSmoking(smoking));
          dispatch(updateDrinking(drinking));
          dispatch(updateHeight(height));
          dispatch(updateBodyType(bodyType));
          dispatch(updateEthnicity(ethnicity));
          dispatch(updateEyeColor(eyeColor));
          dispatch(updateHairColor(hairColor));
          dispatch(updateChildren(children));

          dispatch(updatePersonalityType(personalityType));
          dispatch(updateHobbies(hobbies));
          dispatch(updateFitnessLevel(fitnessLevel));
          dispatch(updateStarSign(starSign));
          dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
          dispatch(updatePersonalityTraits(personalityTraits));

          // About Me
          let tempAboutMeList = [];
          tempAboutMeList?.push({
            name: "personality_type",
            data: personalityType?.length > 0 ? personalityType : null,
          });
          tempAboutMeList?.push({
            name: "hobbies",
            data: hobbies?.length > 0 ? hobbies : null,
          });
          tempAboutMeList?.push({
            name: "star_sign",
            data: starSign,
          });
          tempAboutMeList?.push({
            name: "fitness_level",
            data: fitnessLevel,
          });
          tempAboutMeList?.push({
            name: "dominant_or_submissive",
            data: dominantOrSubmissive,
          });
          tempAboutMeList?.push({
            name: "personality_traits",
            data: personalityTraits,
          });
          dispatch(updateAboutMe(tempAboutMeList));

          // User Utility
          dispatch(updateShouldCallApi(true));
          dispatch(updateProfileStatus(profileStatus));
          dispatch(updatePrivatePhotoAbleToRequest(privatePhotoAbleToRequest));
          dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
          dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
          dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
          dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
          dispatch(updateIsOnline(isOnline));
          dispatch(updateIsBlocked(isBlocked ? true : false));
          dispatch(updateIsSilentInteraction(isSilentInteraction));
          dispatch(updateIsFavorited(isFavorited));
          dispatch(updateIsCallEnabled(isCallEnabled));
          dispatch(updateIsSugarbaby(isSugarbaby));
          dispatch(updateRole(role));
          dispatch(updateLookingFor(interest));

          // Navigate to Profile View
          if (type !== "link") {
            onNavigate(`${routeConst.profile.view.clearPath}${id}`);
          }
          // dispatch(updateUserProfileDialog(true)) // Dialog
          break;
        default:
          break;
      }
    } else {
      switch (profileStatus) {
        case userCardConst.userProfileStatus.live:
        case userCardConst.userProfileStatus.coAnchor:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-S4-4.2-Button",
            },
          });

          dispatch(updateChannelId(channelId));
          dispatch(updateLivestreamerId(livestreamerId));
          dispatch(updateLivestreamerIdInt(idInt));
          dispatch(updateLivestreamerProfilePhoto(profilePhoto));
          dispatch(
            updateLivestreamerAchievements(livestreamingAchievementBadge)
          );
          dispatch(updateLivestreamerUsername(username));
          dispatch(updateLivestreamerLevellingBadge(levellingBadge));
          dispatch(updateLivestreamerFollowers(followerCount));
          dispatch(updateLivestreamerViewers(viewerCount));
          dispatch(updateLivestreamerDiamonds(diamondCount));
          dispatch(updateLivestreamerIsFollowing(isFollowing));

          if (channelId) {
            onNavigate(`${routeConst.live.liveId.clearPath}${channelId}`);
          } else {
            const warningToastObj = {
              message: t("common.something_went_wrong"),
              autoClose: 3000,
            };
            dispatch(updateWarningToast(warningToastObj));
          }

          break;
        case userCardConst.userProfileStatus.inPrivateAvailable:
        case userCardConst.userProfileStatus.inPrivateBusy:
        case userCardConst.userProfileStatus.videoCall:
        case userCardConst.userProfileStatus.normal:
        default:
          break;
      }
    }
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  return (
    <div id="co-anchor-card-subcomponent">
      <div className={`relative ${hasPk ? "dual-pk-border-gradient" : ""}`}>
        <div className="co-anchor-card-container">
          <div className="profile-image-container">
            <div className="host-image-container">
              <CustomAvatar
                className="profile-image"
                src={hostProfilePhoto}
                role={role}
                profileBorder={null}
              />
            </div>

            <div className="co-host-image-container">
              <CustomAvatar
                className="co-host-profile-image"
                src={coHostProfilePhoto}
                role={role}
                profileBorder={null}
                scale={true}
              />
            </div>

            {hasPk && (
              <div className="dual-pk-logo-container">
                {getIcon("pkIcon", "dual-pk-logo")}
              </div>
            )}
          </div>

          <div className="card-details-container">
            {cardStatus === userCardConst.cardStatus.default ? (
              <Link
                className="top-description-container"
                to={`${routeConst.profile.view.clearPath}${id}`}
                onClick={() => onUserClick("link")}
              >
                {/* User Status - Live */}
                {(profileStatus === userCardConst.userProfileStatus.live ||
                  profileStatus ===
                    userCardConst.userProfileStatus.coAnchor) && (
                  <div className="live">
                    <div className="live-status">{t("search.badge.live")}</div>
                    <div className="viewer-count-container">
                      <div className="eye-logo-container">
                        <VisibilityIcon className="eye-logo" />
                      </div>
                      <div className="viewer-count">{viewerCount || 0}</div>
                    </div>
                  </div>
                )}
              </Link>
            ) : (
              <div
                className="top-description-container"
                onClick={() => onUserClick("default")}
              >
                {/* User Status - Live */}
                {(profileStatus === userCardConst.userProfileStatus.live ||
                  profileStatus ===
                    userCardConst.userProfileStatus.coAnchor) && (
                  <div className="live">
                    <div className="live-status">{t("search.badge.live")}</div>
                    <div className="viewer-count-container">
                      <div className="eye-logo-container">
                        <VisibilityIcon className="eye-logo" />
                      </div>
                      <div className="viewer-count">{viewerCount || 0}</div>
                    </div>
                  </div>
                )}

                {/* User Status - Available for Private Call */}
                {profileStatus ===
                  userCardConst.userProfileStatus.inPrivateAvailable && (
                  <div className="private-call-available">
                    {t("search.badge.private_room")}

                    {/* Verified Caller Badge */}
                    {verifiedCaller && (
                      <div
                        className="verified-caller-badge-container"
                        onClick={onOpenVerifiedCallerDialog}
                      >
                        {getIcon(
                          "verifiedCallerBadge",
                          "verified-caller-badge"
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* User Status - Busy in Private Call */}
                {profileStatus ===
                  userCardConst.userProfileStatus.inPrivateBusy && (
                  <div className="private-call-busy">
                    {t("search.badge.busy_in_private")}

                    {/* Verified Caller Badge */}
                    {verifiedCaller && (
                      <div className="verified-caller-badge-container">
                        {getIcon(
                          "verifiedCallerBadge",
                          "verified-caller-badge"
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* User Status - Video Call */}
                {profileStatus ===
                  userCardConst.userProfileStatus.videoCall && (
                  <div className="video-call">
                    {t("search.badge.video_call")}
                  </div>
                )}
              </div>
            )}

            <div className="bottom-description-container">
              <div className="left-detail-container">
                {livestreamingAchievementBadge?.length > 0 && (
                  <div className="livestreaming-achievements-badge-container">
                    {livestreamingAchievementBadge?.map(
                      (achievement, index) => (
                        <img
                          className="livestreaming-achievements-badge"
                          src={achievement?.url}
                          alt={"badge"}
                          key={index}
                        />
                      )
                    )}
                  </div>
                )}

                <div className="username-description">
                  <div className="username">
                    {username || ""}
                    {age && <span>,</span>}
                  </div>
                  {age && <div className="age">{age || ""}</div>}
                  {isOnline && <div className="status"></div>}

                  {/* Verified Profile */}
                  {verifiedProfile && (
                    <div className="verified-profile-badge-container">
                      {getIcon(
                        "verifiedProfileBadge",
                        "verified-profile-badge"
                      )}
                    </div>
                  )}

                  {/* Premium Mini Badge */}
                  {(membershipType === userConst.membershipType.premium ||
                    membershipType === userConst.membershipType.freePremium ||
                    membershipType === userConst.membershipType.diamond) && (
                    <div className="premium-mini-badge-container">
                      {getIcon("premiumMiniBadge", "premium-mini-badge")}
                    </div>
                  )}
                </div>

                <div className="location-description">
                  <div className="location">{location || ""}</div>
                  {levellingBadge && (
                    <div className="badge-container">
                      <img
                        className="badge"
                        src={levellingBadge}
                        alt={"badge"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoAnchorCard;
