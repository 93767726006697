// General
import { createSlice } from "@reduxjs/toolkit";
// Static Data
import user from "../../enums/user";
import registrationConst from "../../const/registrationConst";

const initialState = {
  // Username variables
  username: "",
  birthdate: "",

  // Registration variables
  selectedTags: [],
  annualIncomeData: [],
  annualIncomeIndex: null,
  annualIncome: null,
  networthData: [],
  networthIndex: null,
  networth: null,
  lifestyle: "",
  datingBudget: "",
  relationshipStatus: "",
  position: "",
  industry: "",
  selectedFantasyContainer: null,
  publicFantasies: [],
  privateFantasies: [],
  datingStyles: [],
  ethnicity: "",
  race: "",
  smoking: "",
  drinking: "",
  alcoholPreference: [],
  aboutMeQuestions: [],
  aboutMe: null,
  aboutMeState: [
    {
      questionNumber: 1,
      accordion: false,
      question: null,
      questionIndex: null,
      answers: [],
      description: null,
      min: null,
      max: null,
    },
    {
      questionNumber: 2,
      accordion: false,
      question: null,
      questionIndex: null,
      answers: [],
      description: null,
      min: null,
      max: null,
    },
    {
      questionNumber: 3,
      accordion: false,
      question: null,
      questionIndex: null,
      answers: [],
      description: null,
      min: null,
      max: null,
    },
  ],
  aboutMeList: [],
  aboutMeLength: 0,
  selectedAboutMe: [
    { name: null, data: null },
    { name: null, data: null },
    { name: null, data: null },
  ],
  aboutMeIndex: null,
  aboutMeDisableState: [false, true, true],
  lookingForAgeRangeMin: registrationConst.lookingForAgeRange.min,
  lookingForAgeRangeMax: registrationConst.lookingForAgeRange.max,
  lookingFor: {
    location: [],
    ethnicity: [],
  },
  joinLivestreamCommunity: "",

  // Photos variables
  profilePhoto: null,

  // Utility variables
  registrationNavigationData: [
    {
      id: 0,
      title: "Interests",
      icon: "interests",
      isVisible: true,
      isClickable: true,
      isSkippable: false,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 1,
      title: "Income",
      icon: "income",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY],
    },
    {
      id: 2,
      title: "Net Worth",
      icon: "networth",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY],
    },
    {
      id: 3,
      title: "Lifestyle",
      icon: "lifestyle",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 4,
      title: "Relationship",
      icon: "relationship",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 5,
      title: "Dating Styles",
      icon: "dating-styles",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 6,
      title: "Occupation",
      icon: "occupation",
      isVisible: false,
      isClickable: false,
      isSkippable: false,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 7,
      title: "Ethnicity",
      icon: "ethnicity",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 8,
      title: "Race",
      icon: "race",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 9,
      title: "Smoke",
      icon: "smoke",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 10,
      title: "Drink",
      icon: "drink",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 11,
      title: "Alcohol",
      icon: "alcohol",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 12,
      title: "About Me",
      icon: "about-me",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 13,
      title: "Looking For",
      icon: "looking-for",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 14,
      title: "Livestreaming",
      icon: "livestreaming",
      isVisible: false,
      isClickable: false,
      isSkippable: true,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
    {
      id: 15,
      title: "Photos",
      icon: "photos",
      isVisible: false,
      isClickable: false,
      isSkippable: false,
      skipped: false,
      rolePermission: [user.lookingFor.SUGARDADDY, user.lookingFor.SUGARBABY],
    },
  ],
  registrationActiveTab: 0,
  detailsSignupScroll: true,
  journeyNavigationPassthrough: null,
  journeyDataPassthrough: null,
  profileDataPassthrough: null,
  profilePhotoDataPassthrough: null,
  verificationDataPassthrough: {
    email: {
      verified: false,
      verification_display: false,
      verification_required: false,
    },
    mobile: {
      verified: false,
      verification_display: false,
      verification_required: false,
    },
  },
  verificationNavigatePassthrough: null,
  premiumBenefitsPassthrough: registrationConst.premiumBenefits.unassigned,
  completeJourneyPassthrough: null,
  initiateEmailApiLoading: false,
  aboutMeDialogDataPassthrough: null,
  lastLocation: null,
  skippableJourney: true,
  isProfileMeLoading: false,
  isCompleteJourneyLoading: false,
  isInJourney: false,
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    // Username Functions
    updateUsername: (state, action) => {
      state.username = action.payload;
    },
    updateBirthdate: (state, action) => {
      state.birthdate = action.payload;
    },

    // Registration Functions
    updateSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    updateAnnualIncomeData: (state, action) => {
      state.annualIncomeData = action.payload;
    },
    resetAnnualIncomeData: (state) => {
      state.annualIncomeData = [];
    },
    updateAnnualIncomeIndex: (state, action) => {
      state.annualIncomeIndex = action.payload;
    },
    updateAnnualIncome: (state, action) => {
      state.annualIncome = action.payload;
    },
    updateNetworthData: (state, action) => {
      state.networthData = action.payload;
    },
    resetNetworthData: (state) => {
      state.networthData = [];
    },
    updateNetworthIndex: (state, action) => {
      state.networthIndex = action.payload;
    },
    updateNetworth: (state, action) => {
      state.networth = action.payload;
    },
    updateLifestyle: (state, action) => {
      state.lifestyle = action.payload;
    },
    updateDatingBudget: (state, action) => {
      state.datingBudget = action.payload;
    },
    updateRelationshipStatus: (state, action) => {
      state.relationshipStatus = action.payload;
    },
    updatePosition: (state, action) => {
      state.position = action.payload;
    },
    updateIndustry: (state, action) => {
      state.industry = action.payload;
    },
    updateSelectedFantasyContainer: (state, action) => {
      state.selectedFantasyContainer = action.payload;
    },
    updatePublicFantasies: (state, action) => {
      state.publicFantasies = action.payload;
    },
    updatePrivateFantasies: (state, action) => {
      state.privateFantasies = action.payload;
    },
    updateDatingStyles: (state, action) => {
      state.datingStyles = action.payload;
    },
    updateEthnicity: (state, action) => {
      state.ethnicity = action.payload;
    },
    updateRace: (state, action) => {
      state.race = action.payload;
    },
    updateSmoking: (state, action) => {
      state.smoking = action.payload;
    },
    updateDrinking: (state, action) => {
      state.drinking = action.payload;
    },
    updateAlcoholPreference: (state, action) => {
      state.alcoholPreference = action.payload;
    },
    updateAboutMeQuestions: (state, action) => {
      state.aboutMeQuestions = action.payload;
    },
    updateAboutMe: (state, action) => {
      state.aboutMe = action.payload;
    },
    updateAboutMeState: (state, action) => {
      state.aboutMeState = action.payload;
    },
    updateAboutMeList: (state, action) => {
      state.aboutMeList = action.payload;
    },
    updateAboutMeLength: (state, action) => {
      state.aboutMeLength = action.payload;
    },
    updateSelectedAboutMe: (state, action) => {
      state.selectedAboutMe = action.payload;
    },
    updateAboutMeIndex: (state, action) => {
      state.aboutMeIndex = action.payload;
    },
    updateAboutMeDisableState: (state, action) => {
      state.aboutMeDisableState = action.payload;
    },
    updateLookingForAgeRangeMin: (state, action) => {
      // state.lookingFor.ageRange.min = action.payload;
      state.lookingForAgeRangeMin = action.payload;
    },
    updateLookingForAgeRangeMax: (state, action) => {
      // state.lookingFor.ageRange.max = action.payload;
      state.lookingForAgeRangeMax = action.payload;
    },
    updateLookingForLocation: (state, action) => {
      state.lookingFor.location = action.payload;
    },
    updateLookingForEthnicity: (state, action) => {
      state.lookingFor.ethnicity = action.payload;
    },
    updateJoinLivestreamCommunity: (state, action) => {
      state.joinLivestreamCommunity = action.payload;
    },

    // Photos Functions
    updateProfilePhoto: (state, action) => {
      state.profilePhoto = action.payload;
    },

    // Utility Functions
    resetRegistrationStore: () => {
      return { ...initialState };
    },
    updateRegistrationNavigationData: (state, action) => {
      state.registrationNavigationData = action.payload;
    },
    updateRegistrationActiveTab: (state, action) => {
      state.registrationActiveTab = action.payload;
    },
    updateDetailsSignupScroll: (state, action) => {
      state.detailsSignupScroll = action.payload;
    },
    updateJourneyNavigationPassthrough: (state, action) => {
      state.journeyNavigationPassthrough = action.payload;
    },
    updateJourneyDataPassthrough: (state, action) => {
      state.journeyDataPassthrough = action.payload;
    },
    updateProfileDataPassthrough: (state, action) => {
      state.profileDataPassthrough = action.payload;
    },
    updateProfilePhotoDataPassthrough: (state, action) => {
      state.profilePhotoDataPassthrough = action.payload;
    },
    updateVerificationDataPassthrough: (state, action) => {
      state.verificationDataPassthrough = action.payload;
    },
    updateVerificationNavigatePassthrough: (state, action) => {
      state.verificationNavigatePassthrough = {};
    },
    updatePremiumBenefitsPassthrough: (state, action) => {
      state.premiumBenefitsPassthrough = action.payload;
    },
    updateCompleteJourneyPassthrough: (state, action) => {
      state.completeJourneyPassthrough = action.payload;
    },
    updateInitiateEmailApiLoading: (state, action) => {
      state.initiateEmailApiLoading = action.payload;
    },
    updateAboutMeDialogDataPassthrough: (state, action) => {
      state.aboutMeDialogDataPassthrough = action.payload;
    },
    updateLastLocation: (state, action) => {
      state.lastLocation = action.payload;
    },
    updateSkippableJourney: (state, action) => {
      state.skippableJourney = action.payload;
    },
    updateIsProfileMeLoading: (state, action) => {
      state.isProfileMeLoading = action.payload;
    },
    updateIsCompleteJourneyLoading: (state, action) => {
      state.isCompleteJourneyLoading = action.payload;
    },
    updateIsInJourney: (state, action) => {
      state.isInJourney = action.payload;
    },
  },
});

export const {
  // Username Functions
  updateUsername,
  updateBirthdate,

  // Registration Functions
  updateSelectedTags,
  updateAnnualIncomeData,
  resetAnnualIncomeData,
  updateAnnualIncomeIndex,
  updateAnnualIncome,
  updateNetworthData,
  resetNetworthData,
  updateNetworthIndex,
  updateNetworth,
  updateLifestyle,
  updateDatingBudget,
  updateRelationshipStatus,
  updatePosition,
  updateIndustry,
  updateSelectedFantasyContainer,
  updatePublicFantasies,
  updatePrivateFantasies,
  updateDatingStyles,
  updateEthnicity,
  updateRace,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateAboutMeQuestions,
  updateAboutMe,
  updateAboutMeState,
  updateAboutMeList,
  updateAboutMeLength,
  updateSelectedAboutMe,
  updateAboutMeIndex,
  updateAboutMeDisableState,
  updateLookingForAgeRangeMin,
  updateLookingForAgeRangeMax,
  updateLookingForLocation,
  updateLookingForEthnicity,
  updateJoinLivestreamCommunity,

  // Photos Functions
  updateProfilePhoto,

  // Utility Functions
  resetRegistrationStore,
  updateRegistrationNavigationData,
  updateRegistrationActiveTab,
  updateDetailsSignupScroll,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateProfileDataPassthrough,
  updateProfilePhotoDataPassthrough,
  updateVerificationDataPassthrough,
  updateVerificationNavigatePassthrough,
  updatePremiumBenefitsPassthrough,
  updateCompleteJourneyPassthrough,
  updateInitiateEmailApiLoading,
  updateAboutMeDialogDataPassthrough,
  updateLastLocation,
  updateSkippableJourney,
  updateIsProfileMeLoading,
  updateIsCompleteJourneyLoading,
  updateIsInJourney,
} = registrationSlice.actions;

export default registrationSlice.reducer;
