const editProfileConst = {
  profileDialogView: {
    // Basic Information
    username: "username",
    birthdate: "birthdate",
    // Photos
    photos: "photos",
    // TOR
    tor: "tor",
    // Dating Style
    datingStyle: "datingStyle",
    // Financial Information
    lifestyle: "lifestyle",
    datingBudget: "datingBudget",
    networth: "networth",
    annualIncome: "annualIncome",
    // Appearance
    height: "height",
    bodyType: "bodyType",
    ethnicity: "ethnicity",
    race: "race",
    eyeColor: "eyeColor",
    hairColor: "hairColor",
    // Personal Information
    occupation: "occupation",
    education: "education",
    relationship: "relationship",
    children: "children",
    smoking: "smoking",
    drinking: "drinking",
    alcoholPreference: "alcoholPreference",
    // About Me
    aboutMe: "aboutMe",
    // Looking For
    lookingForAgeRange: "lookingForAgeRange",
    lookingForLocation: "lookingForLocation",
    lookingForEthnicityRace: "lookingForEthnicityRace",
  },
  groupProfileDialogView: {
    // TOR
    photos: "photos",
    tor: "tor",
    datingStyle: "datingStyle",
    financialInformation: "financialInformation",
    appearance: "appearance",
    personalInformation: "personalInformation",
    aboutMe: "aboutMe",
    lookingFor: "lookingFor",
    drinking: "drinking",
  },
};

export default editProfileConst;
