// General
import "./events-tab.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetLivestreamingContestListQuery,
  useLazyGetLivestreamingContestLeaderboardQuery,
} from "../../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../../const/leaderboardConst";
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Livestreaming Leaderboard Functions
  updateLivestreamingTopGiftersEvent,
  resetLivestreamingTopGiftersEvent,
  clearLivestreamingContestId,
  updateLivestreamingContestId,
  updateLivestreamingContestPkId,
} from "../../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import TierTab from "../../../../shared/leaderboard/tier-tab/tier-tab";
import PkTab from "../../../../shared/leaderboard/pk-tab/pk-tab";
import LeaderboardSection from "../../../../shared/leaderboard/leaderboard-section/leaderboard-section";
import TiersInfoDialog from "../../../../shared/dialog-content/tiers-info-dialog/tiers-info-dialog";
import PkInfoDialog from "../../../../shared/dialog-content/pk-info-dialog/pk-info-dialog";

const EventsTab = (props) => {
  const { isStandalone = false } = props;

  // API variables
  const [
    getLivestreamingContestList,
    {
      data: getLivestreamingContestListData,
      error: getLivestreamingContestListErrorData,
      isFetching: getLivestreamingContestListFetching,
      isLoading: getLivestreamingContestListLoading,
      isSuccess: getLivestreamingContestListSuccess,
      isError: getLivestreamingContestListError,
    },
  ] = useLazyGetLivestreamingContestListQuery();
  const [
    getLivestreamingContestLeaderboardTopGifters,
    {
      data: getLivestreamingContestLeaderboardTopGiftersData,
      error: getLivestreamingContestLeaderboardTopGiftersErrorData,
      isFetching: getLivestreamingContestLeaderboardTopGiftersFetching,
      isLoading: getLivestreamingContestLeaderboardTopGiftersLoading,
      isSuccess: getLivestreamingContestLeaderboardTopGiftersSuccess,
      isError: getLivestreamingContestLeaderboardTopGiftersError,
    },
  ] = useLazyGetLivestreamingContestLeaderboardQuery();

  // General variables
  const isMounted = useRef(false);

  // Render variables
  let eventTabsView = [];
  let eventTabsPanelView = [];

  // Redux variables
  const livestreamingTopGiftersEvent = useSelector(
    (state) => state.leaderboard.livestreamingTopGiftersEvent
  );
  const livestreamingContestId = useSelector(
    (state) => state.leaderboard.livestreamingContestId
  );
  const leaderboardTabValue = useSelector(
    (state) => state.leaderboard.leaderboardTabValue
  );
  const leaderboardPullToRefreshPassthrough = useSelector(
    (state) => state.leaderboard.leaderboardPullToRefreshPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();
  const [value, setValue] = useState(0);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
    watchDrag: false,
  });

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let topGiftersParam = {
    category: "live_contest",
    board_type: "top_gifters",
    contest_id: livestreamingContestId,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.1-19.1.1",
      },
    });

    onCallApi(false);
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | interestTab
  useEffect(() => {
    emblaApi?.scrollTo(value);
  }, [value]);

  // Lifecycle | Check for update | Livestreaming Contest List API Response
  useEffect(() => {
    if (
      getLivestreamingContestListFetching ||
      getLivestreamingContestListLoading
    ) {
    } else if (getLivestreamingContestListSuccess) {
      if (getLivestreamingContestListData?.status === 1) {
        for (
          let i = 0;
          i < getLivestreamingContestListData?.data?.results?.length;
          i++
        ) {
          if (
            getLivestreamingContestListData?.data?.results[i]?.contest_type ===
            "single"
          ) {
            dispatch(
              updateLivestreamingContestId(
                getLivestreamingContestListData?.data?.results[0]?.contest_id
              )
            );
          }

          if (
            getLivestreamingContestListData?.data?.results[i]?.contest_type ===
            "pk"
          ) {
            dispatch(
              updateLivestreamingContestPkId(
                getLivestreamingContestListData?.data?.results[i]?.contest_id
              )
            );
          }
        }
      }
    } else if (getLivestreamingContestListError) {
      if (getLivestreamingContestListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingContestListFetching,
    getLivestreamingContestListLoading,
    getLivestreamingContestListSuccess,
    getLivestreamingContestListError,
  ]);

  // Lifecycle | Check for update | Top Gifters API Response
  useEffect(() => {
    if (getLivestreamingContestLeaderboardTopGiftersLoading) {
    } else if (getLivestreamingContestLeaderboardTopGiftersSuccess) {
      if (
        getLivestreamingContestLeaderboardTopGiftersData?.status === 1 &&
        getLivestreamingContestLeaderboardTopGiftersData?.data?.top_gifters
          ?.results?.length > 0
      ) {
        dispatch(resetLivestreamingTopGiftersEvent());
        dispatch(
          updateLivestreamingTopGiftersEvent(
            getLivestreamingContestLeaderboardTopGiftersData?.data?.top_gifters
              ?.results
          )
        );
      }
    } else if (getLivestreamingContestLeaderboardTopGiftersError) {
      if (
        getLivestreamingContestLeaderboardTopGiftersErrorData?.status === 401
      ) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingContestLeaderboardTopGiftersFetching,
    getLivestreamingContestLeaderboardTopGiftersLoading,
    getLivestreamingContestLeaderboardTopGiftersSuccess,
    getLivestreamingContestLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | livestreamingContestId
  useEffect(() => {
    if (!livestreamingContestId) return;

    getLivestreamingContestLeaderboardTopGifters(
      `?cat=${topGiftersParam.category}&board_type=${topGiftersParam.board_type}&contest_id=${topGiftersParam.contest_id}`,
      false
    );
  }, [livestreamingContestId]);

  // Lifecycle | Check for update | leaderboardPullToRefreshPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!leaderboardPullToRefreshPassthrough) return;
      dispatch(clearLivestreamingContestId());

      onCallApi(false);
    } else {
      isMounted.current = true;
    }
  }, [leaderboardPullToRefreshPassthrough]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const onTabChangeBySwipe = (index) => {
    setValue(index);
  };

  // Utility Functions
  const onCallApi = (getFromCache) => {
    if (
      !isStandalone &&
      leaderboardTabValue !== leaderboardConst.type.livestreaming
    )
      return;

    getLivestreamingContestList(null, getFromCache);
  };

  // Render Functions | API Response
  if (
    getLivestreamingContestListFetching ||
    getLivestreamingContestListLoading
  ) {
    return <Spinner />;
  } else if (getLivestreamingContestListSuccess) {
    if (getLivestreamingContestListData?.status === 1) {
      // Organizing Tabs based on API Response
      const results = getLivestreamingContestListData?.data?.results ?? [];
      const addedTierLabels = new Set();
      let tiersLength = 0;
      let pkExist = false;

      eventTabsView = results.flatMap(
        ({
          contest_start_at_epoch,
          contest_end_at_epoch,
          tiers,
          contest_type,
        }) => {
          const tabs = [];

          // Check if there is object with tiers array & now is within contest period
          if (tiers?.length > 0) {
            const now = moment();
            const start = moment.unix(contest_start_at_epoch);
            const end = moment.unix(contest_end_at_epoch);

            tiersLength = tiers?.length;

            if (now.isBetween(start, end)) {
              tiers.forEach((tier, index) => {
                const label = `Tier ${index + 1}`;
                if (!addedTierLabels.has(label)) {
                  tabs.push(
                    <Tab
                      label={t("leaderboard.tier_n", { n: index + 1 })}
                      {...a11yProps(tabs.length)}
                      key={label}
                    />
                  );
                  addedTierLabels.add(label);
                }
              });
            } else {
              // Remove this else statement after business logic is confirmed
              // Without this else statement, the tabs will be empty if now is not within contest period
              tiers.forEach((tier, index) => {
                const label = `Tier ${index + 1}`;
                if (!addedTierLabels.has(label)) {
                  tabs.push(
                    <Tab
                      label={t("leaderboard.tier_n", { n: index + 1 })}
                      {...a11yProps(tabs.length)}
                      key={label}
                    />
                  );
                  addedTierLabels.add(label);
                }
              });
            }
          }

          // Check if there is object with contest_type = "pk"
          if (contest_type === "pk") {
            pkExist = true;
            tabs.push(
              <Tab
                key="pk"
                label="PK"
                icon={
                  <div className="pk-icon-container">
                    {getIcon("leaderboardPkIcon", "pk-icon")}
                  </div>
                }
                iconPosition="start"
                {...a11yProps(tabs.length)}
              />
            );
          }

          return tabs;
        }
      );

      eventTabsPanelView = eventTabsView.flatMap((tab, index) => {
        switch (tab.key) {
          case "Tier 1":
            return (
              <div className="embla__slide" value={value} key={index}>
                {value === index && (
                  <TierTab
                    requireInfoIcon={true}
                    infoType={"tier"}
                    tier={1}
                    userType={leaderboardConst.userType.streamer}
                    seeAllButtonType={isStandalone ? "dialog" : "page"}
                  />
                )}
              </div>
            );
          case "Tier 2":
            return (
              <div className="embla__slide" value={value} key={index}>
                {value === index && (
                  <TierTab
                    requireInfoIcon={false}
                    tier={2}
                    userType={leaderboardConst.userType.streamer}
                    seeAllButtonType={isStandalone ? "dialog" : "page"}
                  />
                )}
              </div>
            );
          case "Tier 3":
            return (
              <div className="embla__slide" value={value} key={index}>
                {value === index && (
                  <TierTab
                    requireInfoIcon={false}
                    tier={3}
                    userType={leaderboardConst.userType.streamer}
                    seeAllButtonType={isStandalone ? "dialog" : "page"}
                  />
                )}
              </div>
            );
          case "pk":
            return (
              <div
                className="embla__slide"
                value={value}
                index={pkExist ? tiersLength : 2}
                key={index}
              >
                {value === index && (
                  <PkTab seeAllButtonType={isStandalone ? "dialog" : "page"} />
                )}
              </div>
            );
          default:
            break;
        }
      });

      return (
        <div id="livestreaming-tab-events-tab-subcomponent">
          <div className="events-tab-container">
            <Tabs
              className="custom-tabs"
              value={value}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              centered
            >
              {eventTabsView}
            </Tabs>

            <div ref={emblaRef} className="embla-events-tab">
              <div className="embla__container">{eventTabsPanelView}</div>
            </div>
          </div>

          {/* Livestreaming | Top Event Gifters */}
          {value !== tiersLength && (
            <LeaderboardSection
              type={leaderboardConst.type.livestreaming}
              recurrence={leaderboardConst.tabs.events.events}
              isLoading={
                !livestreamingTopGiftersEvent?.length &&
                (getLivestreamingContestLeaderboardTopGiftersFetching ||
                  getLivestreamingContestLeaderboardTopGiftersLoading)
              }
              title={t("leaderboard.top_event_gifters")}
              requireInfoIcon={false}
              userList={livestreamingTopGiftersEvent}
              userType={leaderboardConst.userType.gifter}
              seeAllButtonType={isStandalone ? "dialog" : "page"}
            />
          )}

          <TiersInfoDialog />
          <PkInfoDialog />
        </div>
      );
    }
  } else if (getLivestreamingContestListError) {
    if (getLivestreamingContestListErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default EventsTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
