// General
import "./category-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Static Data
import searchConst from "../../../../const/searchConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetDatingSearchLists,
  updateSelectedCategoryTab,
  updateSelectedView,
} from "../../../../redux/store/searchStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { Tabs, Tab, Box, Skeleton, useMediaQuery } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const CategoryTab = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Constant variables
  let categoryTab = searchConst.categoryTab;

  // Redux variables
  const selectedCategoryTab = useSelector(
    (state) => state.search.selectedCategoryTab
  );
  const selectedView = useSelector((state) => state.search.selectedView);
  const isFiltered = useSelector((state) => state.searchFilter.isFiltered);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | isFiltered
  useEffect(() => {
    if (isFiltered) {
      dispatch(updateSelectedCategoryTab("filtered"));
    } else {
      if (selectedCategoryTab === "filtered") {
        dispatch(updateSelectedCategoryTab("recently_active"));
      }
    }
  }, [isFiltered]);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileError) {
      // Send to Sentry
      Sentry.captureMessage(
        "Category Tab /me Error: " + JSON.stringify(getProfileErrorData)
      );
    }
  }, [getProfileError]);

  // Event Handlers | Button
  const onSelectedTab = (item) => {
    if (selectedCategoryTab !== item) {
      TagManager.dataLayer({
        dataLayer: {
          event: `PWA-7-7.1-${item}-Button`,
        },
      });

      dispatch(updateSelectedCategoryTab(item));
      dispatch(resetDatingSearchLists());
    }
  };
  const onToggleView = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-7-7.1-ToggleView-Button",
      },
    });

    dispatch(
      updateSelectedView(
        selectedView === searchConst.userCardDisplay.list
          ? searchConst.userCardDisplay.grid
          : searchConst.userCardDisplay.list
      )
    );
  };
  const onRefreshCategoryTab = () => {
    getProfile(null, false);
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    let tabValue = "";

    if (newValue === 0) {
      tabValue = isFiltered ? "filtered" : "recently_active";
    } else if (newValue === 1) {
      tabValue = isFiltered ? "recently_active" : "nearest";
    } else if (newValue === 2) {
      tabValue = isFiltered ? "nearest" : "newest";
    } else if (newValue === 3) {
      if (isDaddy) {
        tabValue = isFiltered ? "newest" : "college";
      } else {
        tabValue = isFiltered ? "newest" : "premium";
      }
    } else if (newValue == 4) {
      if (isDaddy) {
        tabValue = "college";
      } else {
        tabValue = "premium";
      }
    } else {
      tabValue = "recently_active";
    }

    dispatch(updateSelectedCategoryTab(tabValue));
  };

  // Utility Functions
  const getTabValue = () => {
    const tabMappings = {
      filtered: 0,
      recently_active: isFiltered ? 1 : 0,
      nearest: isFiltered ? 2 : 1,
      newest: isFiltered ? 3 : 2,
      college: isFiltered ? 4 : 3,
      premium: isFiltered ? 4 : 3,
    };

    return tabMappings[selectedCategoryTab] || 0;
  };

  if (getProfileFetching || getProfileLoading) {
    return (
      <div
        id="search-category-tab-skeleton-subcomponent"
        className="apply-padding"
      >
        {isMobile && (
          <div className="category-container">
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
          </div>
        )}

        {!isMobile && (
          <div
            className={`category-form-field-container ${
              !isMobile ? "category-tab-full-width" : ""
            }`}
          >
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
          </div>
        )}
      </div>
    );
  } else if (getProfileSuccess) {
    if (getProfileData?.status === 0) {
      if (isDaddy) {
        categoryTab = searchConst.categoryTab.filter(
          (item) => item.value !== "premium"
        );
      } else {
        categoryTab = searchConst.categoryTab.filter(
          (item) => item.value !== "college"
        );
      }
    }

    return (
      <div
        id="search-category-tab-subcomponent"
        className={!isMobile ? "category-tab-full-width" : "apply-padding"}
      >
        {isMobile && (
          <div className="category-container">
            {categoryTab?.map((item, index) => {
              if (!isFiltered && item?.value === "filtered") {
                return;
              }

              return (
                <div
                  className={`tab-container ${
                    selectedCategoryTab === item?.toApi ? "selected-tab" : ""
                  }`}
                  key={index}
                  onClick={() => onSelectedTab(item?.toApi)}
                >
                  {item?.value === "1-on-1-video" && (
                    <div className="new-badge-container">
                      {getIcon("newBadge", "new-badge")}
                    </div>
                  )}
                  {t(`${item?.jsonTitle}`)}
                </div>
              );
            })}
          </div>
        )}

        {!isMobile && (
          <div
            className={`category-form-field-container ${
              !isMobile ? "category-tab-full-width" : ""
            }`}
          >
            <Tabs
              className="custom-tabs add-underline"
              value={getTabValue()}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              variant="fullWidth"
            >
              {isFiltered && (
                <Tab label={t("search.tabs.filtered")} {...a11yProps(0)} />
              )}
              <Tab label={t("search.tabs.recently_active")} {...a11yProps(1)} />
              <Tab label={t("search.tabs.nearest")} {...a11yProps(2)} />
              <Tab label={t("search.tabs.newest")} {...a11yProps(3)} />
              {!isDaddy && (
                <Tab label={t("search.tabs.premium")} {...a11yProps(4)} />
              )}
              {isDaddy && (
                <Tab label={t("search.tabs.college")} {...a11yProps(5)} />
              )}
            </Tabs>
          </div>
        )}

        {releaseChannel !== utilityConst.releaseChannel.stable && (
          <div className="view-select-container">
            {(!isMobile ||
              selectedView === searchConst.userCardDisplay.grid) && (
              <FormatListBulletedIcon
                className={`list-view-button ${
                  !isMobile && selectedView !== searchConst.userCardDisplay.list
                    ? "deselected-view"
                    : ""
                }`}
                onClick={onToggleView}
              />
            )}
            {(!isMobile ||
              selectedView === searchConst.userCardDisplay.list) && (
              <GridViewIcon
                className={`grid-view-button ${
                  !isMobile && selectedView !== searchConst.userCardDisplay.grid
                    ? "deselected-view"
                    : ""
                }`}
                onClick={onToggleView}
              />
            )}
          </div>
        )}
      </div>
    );
  } else if (getProfileError) {
    return (
      <div id="search-category-error-container" onClick={onRefreshCategoryTab}>
        <RefreshRoundedIcon className="refresh-icon" />
        {t("common.something_went_wrong")}
      </div>
    );
  }
};

export default CategoryTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
