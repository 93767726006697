// General
import "./location.scss";
import { useState, useEffect } from "react";
// Services
import { useUpdateUserLocationMutation } from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetLocation,
  updateLocation,
  updateLocationFormattedAddress,
} from "../../../../../redux/store/editProfileStore";
import {
  updateSuccessToast,
  updateInfoToast,
} from "../../../../../redux/store/toastStore";
// Material UI
import { TextField, IconButton, Divider } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReplayIcon from "@mui/icons-material/Replay";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const Location = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();

  // General variables
  const [searchResult, setSearchResult] = useState();

  // Redux variables
  const location = useSelector((state) => state.editProfile.location);
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Check for update | Update User Location API Response
  useEffect(() => {
    if (updateUserLocationLoading) {
    } else if (updateUserLocationSuccess) {
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateInfoToast(toastObj));
    } else if (updateUserLocationError) {
    }
  }, [
    updateUserLocationLoading,
    updateUserLocationSuccess,
    updateUserLocationError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isLocationValid()) return;

    updateUserLocation(location);
  };
  const onReloadGoogleMaps = () => {
    window.location.reload(true);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (event) => {
    dispatch(updateLocationFormattedAddress(event.target.value));
  };
  const onClearLocation = () => {
    dispatch(updateLocationFormattedAddress(""));
    dispatch(resetLocation());
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let location_data = {
        formatted_address: place?.formatted_address,
      };

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const locationObj = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        location_data,
        type: "user_defined",
      };

      updateUserLocation(locationObj);
      dispatch(updateLocation(locationObj));
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  // Utility Functions
  const isLocationValid = () => {
    if (location?.lat && location?.lng) {
      return true;
    } else {
      return false;
    }
  };

  if (googleMapsLoaded) {
    if (mode === utilityConst.editProfileMode.mobile) {
      return (
        <div id="mobile-edit-profile-location-subcomponent">
          <div className="location-label-container">
            <div className="location-label">{t("profile.location")}</div>
          </div>

          <div className="form-field-container">
            <div className="label-container">
              <div className="label">{t("profile.location")}</div>
            </div>

            {/* Location */}
            <div className="form-container">
              <Autocomplete
                className="autocomplete-wrapper"
                onPlaceChanged={onPlaceChanged}
                onLoad={onLoad}
              >
                <TextField
                  sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                  className="form-field"
                  onChange={onInputChange}
                  value={location?.location_data?.formatted_address}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <IconButton onClick={onClearLocation}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                ></TextField>
              </Autocomplete>
            </div>
          </div>
        </div>
      );
    } else if (mode === utilityConst.editProfileMode.desktop) {
      return (
        <div id="desktop-edit-profile-location-subcomponent">
          {/* In Review */}
          <ModerationStatus
            status={userConst.profileGeneralApprovalStatus.inReview}
          />

          {/* Rejected */}
          <ModerationStatus
            status={userConst.profileGeneralApprovalStatus.rejected}
          />

          <div className="location-static-label-container">
            <div className="location-label">{t("profile.location")}</div>
            <Divider className="short-divider" />
          </div>

          <div className="form-field-padding-container">
            <div className="form-field-container">
              <div className="label-container">
                <div className="label">{t("profile.location")}</div>
              </div>

              {/* Location */}
              <div className="form-container">
                <Autocomplete
                  className="autocomplete-wrapper"
                  onPlaceChanged={onPlaceChanged}
                  onLoad={onLoad}
                >
                  <TextField
                    sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                    className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                    onChange={onInputChange}
                    value={location?.location_data?.formatted_address}
                    variant="outlined"
                    InputProps={{
                      disableunderline: "true",
                      endAdornment: (
                        <IconButton onClick={onClearLocation}>
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  ></TextField>
                </Autocomplete>
              </div>
            </div>
          </div>

          {false && (
            <div className="bottom-section-container">
              <div
                className={`save-button ${
                  isLocationValid() ? "" : "disabled-button"
                }`}
                onClick={onSaveDetails}
              >
                {updateUserLocationLoading ? (
                  <Spinner
                    size={19}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("common.save")
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  } else {
    return (
      <div id="edit-profile-location-subcomponent">
        <div className="reload-location-container" onClick={onReloadGoogleMaps}>
          <ReplayIcon className="reload-icon" />
          <div className="reload-location-label">Location error. Reload</div>
        </div>
      </div>
    );
  }
};

export default Location;
