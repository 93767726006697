// General
import "./profile-about-me.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEducation,
  updateRelationshipStatus,
  updatePosition,
  updateIndustry,
  updateChildren,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
import { updateProfileCompletionPercentage } from "../../../../../redux/store/profileStore";
// i18next
import { t } from "i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const ProfileAboutMe = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const smoking = useSelector((state) => state.userCard.smoking);
  const drinking = useSelector((state) => state.userCard.drinking);
  const height = useSelector((state) => state.userCard.height);
  const bodyType = useSelector((state) => state.userCard.bodyType);
  const education = useSelector((state) => state.userCard.education);
  const relationship = useSelector((state) => state.userCard.relationship);
  const position = useSelector((state) => state.userCard.position);
  const industry = useSelector((state) => state.userCard.industry);
  const children = useSelector((state) => state.userCard.children);
  const ethnicity = useSelector((state) => state.userCard.ethnicity);
  const race = useSelector((state) => state.userCard.race);
  const eyeColor = useSelector((state) => state.userCard.eyeColor);
  const hairColor = useSelector((state) => state.userCard.hairColor);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const isSelf = useSelector((state) => state.userCard.isSelf);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | getProfile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      // dispatch(
      //   updateProfileCompletionPercentage(
      //     getProfileData?.data?.profile_progress?.summary?.progress
      //   )
      // );
      dispatch(updateSmoking(getProfileData?.data?.habit?.smoking));
      dispatch(updateDrinking(getProfileData?.data?.habit?.drinking));
      dispatch(updateHeight(getProfileData?.data?.appearance?.height));
      dispatch(updateBodyType(getProfileData?.data?.appearance?.body_type));
      dispatch(updateEducation(getProfileData?.data?.occupation?.education));
      dispatch(
        updateRelationshipStatus(
          getProfileData?.data?.relationship?.relationship_status
        )
      );
      dispatch(updatePosition(getProfileData?.data?.occupation?.position));
      dispatch(updateIndustry(getProfileData?.data?.occupation?.industry));
      dispatch(updateChildren(getProfileData?.data?.relationship?.children));
      dispatch(updateEthnicity(getProfileData?.data?.appearance?.ethnicity));
      dispatch(updateRace(getProfileData?.data?.appearance?.race));
      dispatch(updateEyeColor(getProfileData?.data?.appearance?.eye_color));
      dispatch(updateHairColor(getProfileData?.data?.appearance?.hair_color));
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (isSelf) return;

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      switch (section) {
        case editProfileConst.profileDialogView.smoking:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.smoking
            )
          );
          break;
        case editProfileConst.profileDialogView.drinking:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.drinking
            )
          );
          break;
        case editProfileConst.profileDialogView.height:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.height
            )
          );
          break;
        case editProfileConst.profileDialogView.bodyType:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.bodyType
            )
          );
          break;
        case editProfileConst.profileDialogView.education:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.education
            )
          );
          break;
        case editProfileConst.profileDialogView.relationship:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.relationship
            )
          );
          break;
        case editProfileConst.profileDialogView.occupation:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.occupation
            )
          );
          break;
        case editProfileConst.profileDialogView.occupation:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.occupation
            )
          );
          break;
        case editProfileConst.profileDialogView.children:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.children
            )
          );
          break;
        case editProfileConst.profileDialogView.ethnicity:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.ethnicity
            )
          );
          break;
        case editProfileConst.profileDialogView.race:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.race
            )
          );
          break;
        case editProfileConst.profileDialogView.eyeColor:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.eyeColor
            )
          );
          break;
        case editProfileConst.profileDialogView.hairColor:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.hairColor
            )
          );
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  if (
    smoking ||
    drinking ||
    height ||
    bodyType ||
    education ||
    relationship ||
    position ||
    industry ||
    children ||
    children === 0
  ) {
    return (
      <div id="profile-about-me-subcomponent">
        <div className="padding-container">
          <div className="about-me-label">{t("profile.about_me")}</div>

          <div className="about-me-container">
            {smoking && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("smoking")}
              >
                {getIcon("smokingIcon", "smoking-icon")}
                {smoking?.locale}
              </div>
            )}

            {drinking && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("drinking")}
              >
                {getIcon("drinkingIcon", "drinking-icon")}
                {drinking?.locale}
              </div>
            )}

            {height && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("height")}
              >
                {getIcon("heightIcon", "height-icon")}
                {height} cm
              </div>
            )}

            {bodyType && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("bodyType")}
              >
                {getIcon("bodyTypeIcon", "body-type-icon")}
                {bodyType?.locale}
              </div>
            )}

            {ethnicity && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("ethnicity")}
              >
                {getIcon("ethnicityIcon", "ethnicity-icon")}
                {ethnicity?.locale}
              </div>
            )}

            {race && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("race")}
              >
                {race?.locale}
              </div>
            )}

            {eyeColor && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("eyeColor")}
              >
                {getIcon("eyeColorIcon", "eye-color-icon")}
                {eyeColor?.locale}
              </div>
            )}

            {hairColor && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("hairColor")}
              >
                {getIcon("hairColorIcon", "hair-color-icon")}
                {hairColor?.locale}
              </div>
            )}

            {education && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("education")}
              >
                {getIcon("educationIcon", "education-icon")}
                {education?.locale}
              </div>
            )}

            {relationship && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("relationship")}
              >
                {getIcon("relationshipIcon", "relationship-icon")}
                {relationship?.locale}
              </div>
            )}

            {position && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("occupation")}
              >
                {getIcon("occupationIcon", "education-icon")}
                {position?.locale}
                {industry?.locale ? `, ${industry?.locale}` : ""}
              </div>
            )}

            {(children || children !== 0) && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("children")}
              >
                {getIcon("kidsIcon", "children-icon")}
                {children} {t("profile.children")}
              </div>
            )}

            {children === 0 && (
              <div
                className={`about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("children")}
              >
                {getIcon("kidsIcon", "children-icon")}
                {t("profile.dont_have_kids")}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileAboutMe;
