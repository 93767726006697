// General
import "./desktop-edit-profile.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfileCompletionQuery,
} from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
import routeConst from "../../../../const/routeConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { Divider, CircularProgress } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";
import BasicInformation from "../edit-profile/basic-information/basic-information";
import TermsOfRelationship from "../edit-profile/terms-of-relationship/terms-of-relationship";
import DatingStyle from "../edit-profile/dating-style/dating-style";
import FinancialInformation from "../edit-profile/financial-information/financial-information";
import Location from "../edit-profile/location/location";
import Appearance from "../edit-profile/apperance/appearance";
import PersonalInformation from "../edit-profile/personal-information/personal-information";
import AboutMe from "../edit-profile/about-me/about-me";
import LookingFor from "../edit-profile/looking-for/looking-for";
import Photos from "../photos/photos";

const DesktopEditProfile = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();

  // General variables
  const [selectedEditMenu, setSelectedEditMenu] = useState("basic-information");

  // Redux variables
  const username = useSelector((state) => state.editProfile.username);
  const usernameNewContent = useSelector(
    (state) => state.editProfile.usernameNewContent
  );
  const usernameStatus = useSelector(
    (state) => state.editProfile.usernameStatus
  );
  const birthdate = useSelector((state) => state.editProfile.birthdate);
  const location = useSelector((state) => state.editProfile.location);
  const annualIncome = useSelector((state) => state.editProfile.annualIncome);
  const networth = useSelector((state) => state.editProfile.networth);
  const datingBudget = useSelector((state) => state.editProfile.datingBudget);
  const position = useSelector((state) => state.editProfile.position);
  const lifestyle = useSelector((state) => state.editProfile.lifestyle);
  const education = useSelector((state) => state.editProfile.education);
  const relationshipStatus = useSelector(
    (state) => state.editProfile.relationshipStatus
  );
  const smoking = useSelector((state) => state.editProfile.smoking);
  const drinking = useSelector((state) => state.editProfile.drinking);
  const height = useSelector((state) => state.editProfile.height);
  const bodyType = useSelector((state) => state.editProfile.bodyType);
  const ethnicity = useSelector((state) => state.editProfile.ethnicity);
  const race = useSelector((state) => state.editProfile.race);
  const eyeColor = useSelector((state) => state.editProfile.eyeColor);
  const hairColor = useSelector((state) => state.editProfile.hairColor);
  const children = useSelector((state) => state.editProfile.children);
  const profilePhoto = useSelector((state) => state.editProfile.profilePhoto);
  const publicPhotos = useSelector((state) => state.editProfile.publicPhotos);
  const privatePhotos = useSelector((state) => state.editProfile.privatePhotos);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, false);
    getProfileCompletion(null, false);
  }, []);

  // Event Handlers | Button
  const onSelectEditMenu = (state) => {
    setSelectedEditMenu(state);
  };

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Utility Functions
  const isBasicInformationValid = () => {
    if (
      ((usernameNewContent && usernameNewContent !== "") ||
        (username && username !== "")) &&
      birthdate &&
      birthdate !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isTorValid = () => {
    if (getProfileData?.data?.tor_tags?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const isDatingStyleValid = () => {
    if (
      getProfileCompletionData?.data?.relationship?.dating_style?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isFinancialInformationValid = () => {
    if (isDaddy) {
      if (annualIncome && networth && datingBudget) {
        return true;
      } else {
        return false;
      }
    } else {
      if (lifestyle) {
        return true;
      } else {
        return false;
      }
    }
  };
  const isLocationValid = () => {
    if (location?.lat && location?.lng) {
      return true;
    } else {
      return false;
    }
  };
  const isAppearanceValid = () => {
    if (height && bodyType && ethnicity && eyeColor && hairColor) {
      // Removed due to clashing with different country
      // if (ethnicity === "asian" && !race) {
      //   return false;
      // }
      return true;
    } else {
      return false;
    }
  };
  const isPersonalInformationValid = () => {
    if (
      position &&
      education &&
      relationshipStatus &&
      (children || children === 0) &&
      smoking &&
      drinking
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isAboutMeValid = () => {
    const personalityType =
      getProfileCompletionData?.data?.personality?.personality_type;
    const hobbies = getProfileCompletionData?.data?.habit?.hobbies;
    const starSign = getProfileCompletionData?.data?.personality?.star_sign;
    const fitnessLevel = getProfileCompletionData?.data?.habit?.fitness_level;
    const dominantOrSubmissive =
      getProfileCompletionData?.data?.personality?.dominant_or_submissive;
    const personalityTraits =
      getProfileCompletionData?.data?.personality?.personality_traits;

    let tempAboutMeLength = 0;

    if (personalityType?.length > 0) {
      tempAboutMeLength++;
    }
    if (hobbies?.length > 0) {
      tempAboutMeLength++;
    }
    if (starSign) {
      tempAboutMeLength++;
    }
    if (fitnessLevel) {
      tempAboutMeLength++;
    }
    if (dominantOrSubmissive) {
      tempAboutMeLength++;
    }
    if (personalityTraits) {
      tempAboutMeLength++;
    }

    return tempAboutMeLength >= 3;
  };
  const isLookingForValid = () => {
    return (
      getProfileCompletionData?.data?.looking_fors?.age?.min &&
      getProfileCompletionData?.data?.looking_fors?.age?.max &&
      getProfileCompletionData?.data?.looking_fors?.locations?.length > 0 &&
      getProfileCompletionData?.data?.looking_fors?.ethnicity?.length > 0
    );
  };
  const isPhotosValid = () => {
    let profilePhotoValid = true;
    let publicPhotosValid = true;
    let privatePhotosValid = true;

    if (profilePhoto) {
      if (profilePhoto?.photo_status === "REJECTED") {
        profilePhotoValid = false;
      }
    } else {
      profilePhotoValid = false;
    }

    for (let i = 0; i < publicPhotos?.length; i++) {
      if (publicPhotos[i]?.photo_status === "REJECTED") {
        publicPhotosValid = false;
        break;
      }
    }

    for (let i = 0; i < privatePhotos?.length; i++) {
      if (privatePhotos[i]?.photo_status === "REJECTED") {
        privatePhotosValid = false;
        break;
      }
    }

    return profilePhotoValid && publicPhotosValid && privatePhotosValid;
  };

  return (
    <div id="desktop-profile-edit-page">
      <div className="max-width-container">
        <div className="edit-menu-container">
          {profileCompletionPercentage < 100 && (
            <div className="profile-photo-container">
              {profileCompletionPercentage < 100 && (
                <CircularProgress
                  className="default-circular-progress-bar"
                  variant="determinate"
                  value={100}
                />
              )}
              {profileCompletionPercentage < 100 && (
                <CircularProgress
                  className="circular-progress-bar"
                  variant="determinate"
                  value={profileCompletionPercentage || 0}
                />
              )}

              <CustomAvatar
                className={`profile-photo ${
                  profileCompletionPercentage < 100
                    ? "profile-photo-absolute"
                    : ""
                }`}
                src={
                  getProfileCompletionData?.data?.profile_photo
                    ?.photo_status !== "REJECTED"
                    ? getProfileCompletionData?.data?.profile_photo
                        ?.original_photo
                    : null
                }
                role={getProfileCompletionData?.data?.role}
                profileBorder={
                  getProfileCompletionData?.data?.profile_decorations
                    ?.profile_border_url
                }
              />

              {profileCompletionPercentage < 100 && (
                <div
                  className="profile-completed-percentage-container"
                  onClick={() => onNavigate(routeConst.profile.completion.path)}
                >
                  {profileCompletionPercentage || 0}%
                </div>
              )}
            </div>
          )}

          <div className="settings-label-container">
            <div className="settings-label">{t("profile.settings")}</div>
            <Divider className="short-divider" />
          </div>

          {/* Basic Information */}
          <div
            className="basic-information-button-container"
            onClick={() => onSelectEditMenu("basic-information")}
          >
            <div
              className={`basic-information-icon-container ${
                selectedEditMenu === "basic-information"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              {getIcon("userIcon", "user-icon")}
            </div>
            <div
              className={`basic-information-label ${
                selectedEditMenu === "basic-information"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              {t("profile.basic_information")}
            </div>
            <div className="complete-status-container">
              {isBasicInformationValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Terms of Relationship */}
          <div
            className="terms-of-relationship-button-container"
            onClick={() => onSelectEditMenu("terms-of-relationship")}
          >
            <div
              className={`terms-of-relationship-icon-container ${
                selectedEditMenu === "terms-of-relationship"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              {getIcon("priceTagProfileIcon", "price-tag-icon")}
            </div>
            <div
              className={`terms-of-relationship-label ${
                selectedEditMenu === "terms-of-relationship"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              <Trans
                i18nKey={"profile.terms_of_relationship"}
                components={{ sup: <sup className="trade-mark-label" /> }}
              />
            </div>
            <div className="complete-status-container">
              {isTorValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Dating Style */}
          <div
            className="terms-of-relationship-button-container"
            onClick={() => onSelectEditMenu("dating-style")}
          >
            <div
              className={`terms-of-relationship-icon-container ${
                selectedEditMenu === "dating-style" ? "selected-edit-menu" : ""
              }`}
            >
              {getIcon("priceTagProfileIcon", "price-tag-icon")}
            </div>
            <div
              className={`terms-of-relationship-label ${
                selectedEditMenu === "dating-style" ? "selected-edit-menu" : ""
              }`}
            >
              {t("profile.dating_style")}
            </div>
            <div className="complete-status-container">
              {isDatingStyleValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Financial Information */}
          <div
            className="financial-information-button-container"
            onClick={() => onSelectEditMenu("financial-information")}
          >
            <div
              className={`financial-information-icon-container ${
                selectedEditMenu === "financial-information"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              {getIcon("bankIcon", "bank-icon")}
            </div>
            <div
              className={`financial-information-label ${
                selectedEditMenu === "financial-information"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              {t("profile.financial_information")}
            </div>
            <div className="complete-status-container">
              {isFinancialInformationValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Location */}
          <div
            className="location-button-container"
            onClick={() => onSelectEditMenu("location")}
          >
            <div
              className={`location-icon-container ${
                selectedEditMenu === "location" ? "selected-edit-menu" : ""
              }`}
            >
              {getIcon("pinLocationIcon", "location-icon")}
            </div>
            <div
              className={`location-label ${
                selectedEditMenu === "location" ? "selected-edit-menu" : ""
              }`}
            >
              {t("profile.location")}
            </div>
            <div className="complete-status-container">
              {isLocationValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Appearance */}
          <div
            className="appearance-button-container"
            onClick={() => onSelectEditMenu("appearance")}
          >
            <div
              className={`appearance-icon-container ${
                selectedEditMenu === "appearance" ? "selected-edit-menu" : ""
              }`}
            >
              {getIcon("profileSmileyIcon", "smiley-icon")}
            </div>
            <div
              className={`appearance-label ${
                selectedEditMenu === "appearance" ? "selected-edit-menu" : ""
              }`}
            >
              {t("profile.appearance")}
            </div>
            <div className="complete-status-container">
              {isAppearanceValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Personal Information */}
          <div
            className="personal-information-button-container"
            onClick={() => onSelectEditMenu("personal-information")}
          >
            <div
              className={`personal-information-icon-container ${
                selectedEditMenu === "personal-information"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              {getIcon("materialLockIcon", "lock-icon")}
            </div>
            <div
              className={`personal-information-label ${
                selectedEditMenu === "personal-information"
                  ? "selected-edit-menu"
                  : ""
              }`}
            >
              {t("profile.personal_information")}
            </div>
            <div className="complete-status-container">
              {isPersonalInformationValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* About Me */}
          <div
            className="description-button-container"
            onClick={() => onSelectEditMenu("about-me")}
          >
            <div
              className={`description-icon-container ${
                selectedEditMenu === "about-me" ? "selected-edit-menu" : ""
              }`}
            >
              {getIcon("descriptionIcon", "description-icon")}
            </div>
            <div
              className={`description-label ${
                selectedEditMenu === "about-me" ? "selected-edit-menu" : ""
              }`}
            >
              {t("profile.about_me")}
            </div>
            <div className="complete-status-container">
              {isAboutMeValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Looking For */}
          <div
            className="description-button-container"
            onClick={() => onSelectEditMenu("looking-for")}
          >
            <div
              className={`description-icon-container ${
                selectedEditMenu === "looking-for" ? "selected-edit-menu" : ""
              }`}
            >
              {getIcon("descriptionIcon", "description-icon")}
            </div>
            <div
              className={`description-label ${
                selectedEditMenu === "looking-for" ? "selected-edit-menu" : ""
              }`}
            >
              {t("profile.looking_for")}
            </div>
            <div className="complete-status-container">
              {isLookingForValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>

          {/* Photos */}
          <div
            className="photos-button-container"
            onClick={() => onSelectEditMenu("photos")}
          >
            <div
              className={`photos-icon-container ${
                selectedEditMenu === "photos" ? "selected-edit-menu" : ""
              }`}
            >
              {getIcon("pictureFrameIcon", "frame-icon")}
            </div>
            <div
              className={`photos-label ${
                selectedEditMenu === "photos" ? "selected-edit-menu" : ""
              }`}
            >
              {t("profile.edit_photos")}
            </div>
            <div className="complete-status-container">
              {isPhotosValid()
                ? getIcon("tickIcon", "done-icon")
                : getIcon("exclamationMarkIcon", "warning-icon")}
            </div>
          </div>
        </div>

        <div className="edit-content-container">
          {selectedEditMenu === "basic-information" && (
            <BasicInformation mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "terms-of-relationship" && (
            <TermsOfRelationship mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "dating-style" && (
            <DatingStyle mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "financial-information" && (
            <FinancialInformation mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "location" && (
            <Location mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "appearance" && (
            <Appearance mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "personal-information" && (
            <PersonalInformation mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "about-me" && (
            <AboutMe mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "looking-for" && (
            <LookingFor mode={utilityConst.editProfileMode.desktop} />
          )}

          {selectedEditMenu === "photos" && (
            <Photos mode={utilityConst.editProfileMode.desktop} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopEditProfile;
