// General
import "./edit-about-me.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMe,
  updateAboutMeList,
  updateAboutMeLength,
  updatePersonalityTraits,
  updatePersonalityType,
  updateStarSign,
  updateDominantOrSubmissive,
  updateFitnessLevel,
  updateHobbies,
  updateIsEditAboutMeNext,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import {
  updateSelectedAboutMe,
  updateAboutMe as updateRegistrationAboutMe,
  updateAboutMeList as updateRegistrationAboutMeList,
} from "../../../../../redux/store/registrationStore";
import {
  updateUpdateProfileFullscreenDialog,
  updateUpdateRegistrationAboutMeDialog,
} from "../../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../../redux/store/toastStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditAboutMe = (props) => {
  const { addPaddingTop = false, state = "editProfile" } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isNext, setIsNext] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [description, setDescription] = useState("");

  // Redux variables
  const aboutMe = useSelector((state) => state.editProfile.aboutMe);
  const personalityTraits = useSelector(
    (state) => state.editProfile.personalityTraits
  );
  const personalityType = useSelector(
    (state) => state.editProfile.personalityType
  );
  const starSign = useSelector((state) => state.editProfile.starSign);
  const dominantOrSubmissive = useSelector(
    (state) => state.editProfile.dominantOrSubmissive
  );
  const fitnessLevel = useSelector((state) => state.editProfile.fitnessLevel);
  const hobbies = useSelector((state) => state.editProfile.hobbies);
  const aboutMeList = useSelector((state) => state.registration.aboutMeList);
  const selectedAboutMe = useSelector(
    (state) => state.registration.selectedAboutMe
  );
  const aboutMeIndex = useSelector((state) => state.registration.aboutMeIndex);
  const isEditAboutMeNext = useSelector(
    (state) => state.editProfile.isEditAboutMeNext
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);

    return () => {
      dispatch(updateAboutMe(null));
    };
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );

      // About Me
      const personalityTraits =
        updateProfileData?.data?.user?.personality?.personality_traits;
      const personalityType =
        updateProfileData?.data?.user?.personality?.personality_type;
      const starSign = updateProfileData?.data?.user?.personality?.star_sign;
      const dominantOrSubmissive =
        updateProfileData?.data?.user?.personality?.dominant_or_submissive;
      const fitnessLevel = updateProfileData?.data?.user?.habit?.fitness_level;
      const hobbies = updateProfileData?.data?.user?.habit?.hobbies;

      dispatch(updatePersonalityTraits(personalityTraits));
      dispatch(updatePersonalityType(personalityType));
      dispatch(updateStarSign(starSign));
      dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
      dispatch(updateFitnessLevel(fitnessLevel));
      dispatch(updateHobbies(hobbies));

      let tempAboutMeList = [];
      let tempAboutMeLength = 0;

      tempAboutMeList?.push({
        name: "personality_type",
        data: personalityType?.length > 0 ? personalityType : null,
      });

      if (personalityType?.length > 0) {
        tempAboutMeLength++;
      }

      tempAboutMeList.push({
        name: "hobbies",
        data: hobbies?.length > 0 ? hobbies : null,
      });

      if (hobbies?.length > 0) {
        tempAboutMeLength++;
      }

      tempAboutMeList?.push({
        name: "star_sign",
        data: starSign,
      });

      if (starSign) {
        tempAboutMeLength++;
      }

      tempAboutMeList?.push({
        name: "fitness_level",
        data: fitnessLevel,
      });

      if (fitnessLevel) {
        tempAboutMeLength++;
      }

      tempAboutMeList?.push({
        name: "dominant_or_submissive",
        data: dominantOrSubmissive,
      });

      if (dominantOrSubmissive) {
        tempAboutMeLength++;
      }

      tempAboutMeList?.push({
        name: "personality_traits",
        data: personalityTraits,
      });

      if (personalityTraits) {
        tempAboutMeLength++;
      }

      dispatch(updateAboutMeList(tempAboutMeList));
      dispatch(updateAboutMeLength(tempAboutMeLength));
      dispatch(updateIsEditAboutMeNext(null));
      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (updateProfileError) {
      const errorToast = {
        message: updateProfileErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToast));
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | aboutMe
  useEffect(() => {
    if (!aboutMe || !getJourneyQuestionsSuccess) return;

    switch (aboutMe?.name) {
      case "personality_traits":
        setSelectedQuestion(getPersonalityTraitsData());
        break;
      case "personality_type":
        setSelectedQuestion(getPersonalityTypeData());
        break;
      case "star_sign":
        setSelectedQuestion(getStarSignData());
        break;
      case "dominant_or_submissive":
        setSelectedQuestion(getDominantOrSubmissiveData());
        break;
      case "fitness_level":
        setSelectedQuestion(getFitnessLevelData());
        break;
      case "hobbies":
        setSelectedQuestion(getHobbiesData());
        break;
      default:
        break;
    }

    setSelectedAnswer(
      aboutMe?.data?.length > 0 ? aboutMe?.data : [aboutMe?.data]
    );
    setIsNext(true);
  }, [aboutMe, getJourneyQuestionsSuccess]);

  // Lifecycle | Check for update | isEditAboutMeNext
  useEffect(() => {
    setIsNext(isEditAboutMeNext);

    if (isEditAboutMeNext === false) {
      if (state === "registration") {
        let selectedAboutMeTemp = JSON.parse(JSON.stringify(selectedAboutMe));
        selectedAboutMeTemp[aboutMeIndex].name = null;
        selectedAboutMeTemp[aboutMeIndex].data = null;

        let aboutMeListTemp = JSON.parse(JSON.stringify(aboutMeList));
        for (let i = 0; i < aboutMeListTemp?.length; i++) {
          if (aboutMeListTemp[i]?.name === selectedQuestion?.question) {
            aboutMeListTemp[i].data = null;
            break;
          }
        }

        dispatch(updateSelectedAboutMe(selectedAboutMeTemp));
        dispatch(updateRegistrationAboutMeList(aboutMeListTemp));
      }
      setSelectedQuestion(null);
      setSelectedAnswer([]);
      setDescription("");
    }
  }, [isEditAboutMeNext]);

  // Event Handlers | Button
  const onSelectQuestion = (question) => {
    setSelectedQuestion(question);
    setIsNext(true);
    dispatch(updateIsEditAboutMeNext(true));
  };
  const onNextPage = () => {
    if (isNext) {
      if (!isFormValid()) return;

      if (state === "editProfile") {
        let editProfileObj = {
          profile: {},
        };

        switch (selectedQuestion?.question) {
          case "personality_traits":
            editProfileObj.profile.personality_traits = selectedAnswer[0]?.id;
            break;
          case "personality_type":
            editProfileObj.profile.personality_type = selectedAnswer?.map(
              (item) => item?.id
            );
            break;
          case "star_sign":
            editProfileObj.profile.star_sign = selectedAnswer[0]?.id;
            break;
          case "dominant_or_submissive":
            editProfileObj.profile.dominant_or_submissive =
              selectedAnswer[0]?.id;
            break;
          case "fitness_level":
            editProfileObj.profile.fitness_level = selectedAnswer[0]?.id;
            break;
          case "hobbies":
            editProfileObj.profile.hobbies = selectedAnswer?.map(
              (item) => item?.id
            );
            break;
          default:
            break;
        }

        updateProfile(editProfileObj);
      } else if (state === "registration") {
        let registrationObj = {
          journey_data: {
            profile: {},
          },
        };

        let answers;
        switch (selectedQuestion?.question) {
          case "personality_traits":
            registrationObj.journey_data.profile.personality_traits =
              selectedAnswer[0]?.id;
            answers = selectedAnswer[0];
            break;
          case "personality_type":
            registrationObj.journey_data.profile.personality_type =
              selectedAnswer?.map((item) => item?.id);
            answers = selectedAnswer;
            break;
          case "star_sign":
            registrationObj.journey_data.profile.star_sign =
              selectedAnswer[0]?.id;
            answers = selectedAnswer[0];
            break;
          case "dominant_or_submissive":
            registrationObj.journey_data.profile.dominant_or_submissive =
              selectedAnswer[0]?.id;
            answers = selectedAnswer[0];
            break;
          case "fitness_level":
            registrationObj.journey_data.profile.fitness_level =
              selectedAnswer[0]?.id;
            answers = selectedAnswer[0];
            break;
          case "hobbies":
            registrationObj.journey_data.profile.hobbies = selectedAnswer?.map(
              (item) => item?.id
            );
            answers = selectedAnswer;
            break;
          default:
            break;
        }

        let aboutMeListTemp = JSON.parse(JSON.stringify(aboutMeList));

        aboutMeListTemp?.forEach((item) => {
          if (item?.name === selectedQuestion?.question) {
            item.data = answers;
          }
        });

        let selectedAboutMeTemp = JSON.parse(JSON.stringify(selectedAboutMe));
        selectedAboutMeTemp[aboutMeIndex].name = selectedQuestion?.question;
        selectedAboutMeTemp[aboutMeIndex].data = answers;

        dispatch(updateSelectedAboutMe(selectedAboutMeTemp));
        dispatch(updateRegistrationAboutMeList(aboutMeListTemp));
        dispatch(updateIsEditAboutMeNext(null));
        dispatch(updateUpdateRegistrationAboutMeDialog(false));
      }
    } else {
      setIsNext(true);
    }
  };
  const onSelectAnswer = (answer) => {
    let answersTemp = [...selectedAnswer];

    if (!answersTemp?.some((item) => item?.id === answer?.id)) {
      if (answersTemp?.length < returnMaxAboutMe()) {
        answersTemp?.push(answer);
      } else if (returnMaxAboutMe() === 1) {
        answersTemp = [answer];
      }
    } else {
      answersTemp = answersTemp?.filter((item) => item?.id !== answer?.id);
    }

    if (answer?.display_description) {
      setDescription(answer?.display_description);
    }

    setSelectedAnswer(answersTemp);
  };

  // Utility Functions
  const isFormValid = () => {
    if (isNext) {
      return (
        selectedAnswer?.length > returnMinAboutMe() - 1 && !updateProfileLoading
      );
    } else {
      return selectedQuestion;
    }
  };
  const getAboutMeData = () => {
    let personalityTraitsData = getPersonalityTraitsData();
    let personalityTypeData = getPersonalityTypeData();
    let starSignData = getStarSignData();
    let dominantOrSubmissiveData = getDominantOrSubmissiveData();
    let fitnessLevelData = getFitnessLevelData();
    let hobbiesData = getHobbiesData();

    if (state === "editProfile") {
      let temp = [];

      if (
        personalityTypeData &&
        (!personalityType || personalityType?.length <= 0)
      ) {
        temp?.push(personalityTypeData);
      }

      if (hobbiesData && (!hobbies || hobbies?.length <= 0)) {
        temp?.push(hobbiesData);
      }

      if (fitnessLevelData && !fitnessLevel) {
        temp?.push(fitnessLevelData);
      }

      if (starSignData && !starSign) {
        temp?.push(starSignData);
      }

      if (dominantOrSubmissiveData && !dominantOrSubmissive) {
        temp?.push(dominantOrSubmissiveData);
      }

      if (personalityTraitsData && !personalityTraits) {
        temp?.push(personalityTraitsData);
      }

      return temp;
    } else if (state === "registration") {
      let temp = [];

      if (
        personalityTypeData &&
        aboutMeList?.find((item) => {
          if (item?.name === "personality_type") {
            return !item?.data || item?.data?.length <= 0;
          }
        })
      ) {
        temp?.push(personalityTypeData);
      }

      if (
        hobbiesData &&
        aboutMeList?.find((item) => {
          if (item?.name === "hobbies") {
            return !item?.data || item?.data?.length <= 0;
          }
        })
      ) {
        temp?.push(hobbiesData);
      }

      if (
        fitnessLevelData &&
        aboutMeList?.find((item) => {
          if (item?.name === "fitness_level") {
            return !item?.data;
          }
        })
      ) {
        temp?.push(fitnessLevelData);
      }

      if (
        starSignData &&
        aboutMeList?.find((item) => {
          if (item?.name === "star_sign") {
            return !item?.data;
          }
        })
      ) {
        temp?.push(starSignData);
      }

      if (
        dominantOrSubmissiveData &&
        aboutMeList?.find((item) => {
          if (item?.name === "dominant_or_submissive") {
            return !item?.data;
          }
        })
      ) {
        temp?.push(dominantOrSubmissiveData);
      }

      if (
        personalityTraitsData &&
        aboutMeList?.find((item) => {
          if (item?.name === "personality_traits") {
            return !item?.data;
          }
        })
      ) {
        temp?.push(personalityTraitsData);
      }

      return temp;
    }
  };
  const getPersonalityTraitsData = () => {
    const question = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "personality_traits"
    )?.category;
    const answers = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "personality_traits"
    )?.selections;
    const min = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "personality_traits"
    )?.min_selection;
    const max = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "personality_traits"
    )?.max_selection;

    const combined = {
      question,
      answers,
      min,
      max,
    };
    return combined;
  };
  const getPersonalityTypeData = () => {
    const question = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "personality_type"
    )?.category;
    const answers = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "personality_type"
    )?.selections;
    const min = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "personality_type"
    )?.min_selection;
    const max = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "personality_type"
    )?.max_selection;

    const combined = {
      question,
      answers,
      min,
      max,
    };
    return combined;
  };
  const getStarSignData = () => {
    const question = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "star_sign"
    )?.category;
    const answers = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "star_sign"
    )?.selections;
    const min = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "star_sign"
    )?.min_selection;
    const max = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "star_sign"
    )?.max_selection;

    const combined = {
      question,
      answers,
      min,
      max,
    };
    return combined;
  };
  const getDominantOrSubmissiveData = () => {
    const question = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "dominant_or_submissive"
    )?.category;
    const answers = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "dominant_or_submissive"
    )?.selections;
    const min = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "dominant_or_submissive"
    )?.min_selection;
    const max = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "dominant_or_submissive"
    )?.max_selection;

    const combined = {
      question,
      answers,
      min,
      max,
    };
    return combined;
  };
  const getFitnessLevelData = () => {
    const question = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "fitness_level"
    )?.category;
    const answers = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "fitness_level"
    )?.selections;
    const min = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "fitness_level"
    )?.min_selection;
    const max = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "fitness_level"
    )?.max_selection;

    const combined = {
      question,
      answers,
      min,
      max,
    };
    return combined;
  };
  const getHobbiesData = () => {
    const question = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "hobbies"
    )?.category;
    const answers = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "hobbies"
    )?.selections;
    const min = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "hobbies"
    )?.min_selection;
    const max = getJourneyQuestionsData?.questions?.find(
      (item) => item?.category === "hobbies"
    )?.max_selection;

    const combined = {
      question,
      answers,
      min,
      max,
    };
    return combined;
  };
  const getSelectedQuestionAnswers = () => {
    switch (selectedQuestion?.question) {
      case "personality_traits":
        return selectedQuestion?.answers;
      case "personality_type":
        return selectedQuestion?.answers;
      case "star_sign":
        return selectedQuestion?.answers;
      case "dominant_or_submissive":
        return selectedQuestion?.answers;
      case "fitness_level":
        return selectedQuestion?.answers;
      case "hobbies":
        let organizedHobbiesArray = [];

        selectedQuestion?.answers?.forEach((hobby) => {
          let getSubCategoryIndex = organizedHobbiesArray?.findIndex(
            (organizedHobby) =>
              organizedHobby?.sub_category === hobby?.sub_category
          );

          if (getSubCategoryIndex === -1) {
            organizedHobbiesArray.push({
              sub_category: hobby?.sub_category,
              hobbies: [hobby],
            });
          } else {
            organizedHobbiesArray[getSubCategoryIndex].hobbies.push(hobby);
          }
        });

        return organizedHobbiesArray;
      default:
        return [];
    }
  };
  const getHobbyCategoryName = (categoryName) => {
    const category = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "hobbies_category"
    )?.selections;

    const selectedCategory = category?.find(
      (item) => item?.tag === categoryName
    );

    return selectedCategory?.display_locale;
  };
  const checkAboutMe = (answer) => {
    switch (selectedQuestion?.question) {
      case "personality_traits":
        return selectedAnswer?.find((item) => item?.id === answer?.id);
      case "personality_type":
        return selectedAnswer?.find((item) => item?.id === answer?.id);
      case "star_sign":
        return selectedAnswer?.find((item) => item?.id === answer?.id);
      case "dominant_or_submissive":
        return selectedAnswer?.find((item) => item?.id === answer?.id);
      case "fitness_level":
        return selectedAnswer?.find((item) => item?.id === answer?.id);
      case "hobbies":
        return selectedAnswer?.find((item) => item?.id === answer?.id);
      default:
        break;
    }
  };
  const returnMinAboutMe = () => {
    switch (selectedQuestion?.question) {
      case "personality_traits":
        return selectedQuestion?.min;
      case "personality_type":
        return selectedQuestion?.min;
      case "star_sign":
        return selectedQuestion?.min;
      case "dominant_or_submissive":
        return selectedQuestion?.min;
      case "fitness_level":
        return selectedQuestion?.min;
      case "hobbies":
        return selectedQuestion?.min;
      default:
        return 1;
    }
  };
  const returnMaxAboutMe = () => {
    switch (selectedQuestion?.question) {
      case "personality_traits":
        return selectedQuestion?.max;
      case "personality_type":
        return selectedQuestion?.max;
      case "star_sign":
        return selectedQuestion?.max;
      case "dominant_or_submissive":
        return selectedQuestion?.max;
      case "fitness_level":
        return selectedQuestion?.max;
      case "hobbies":
        return selectedQuestion?.max;
      default:
        return 1;
    }
  };
  const returnTitle = (tag) => {
    switch (tag) {
      case "personality_traits":
        return (
          <Trans
            i18nKey={"profile.are_you_introvert"}
            components={{ br: <br /> }}
          />
        );
      case "personality_type":
        return t("profile.whats_your_personality_type");
      case "star_sign":
        return t("profile.whats_your_horoscope_sign");
      case "dominant_or_submissive":
        return t("profile.are_you_dominant_or_submissive");
      case "fitness_level":
        return t("profile.do_you_work_out");
      case "hobbies":
        return t("profile.what_are_your_hobbies");
    }
  };
  const returnIcon = (tag) => {
    switch (tag) {
      case "personality_traits":
        return "personalityTraitsIcon";
      case "personality_type":
        return "personalityTypeIcon";
      case "star_sign":
        return "starSignIcon";
      case "dominant_or_submissive":
        return "dominantSubmissiveIcon";
      case "fitness_level":
        return "fitnessIcon";
      case "hobbies":
        return "hobbiesIcon";
    }
  };

  return (
    <div id="about-me-subcomponent">
      <div
        className={`padding-container ${
          isNext ? "apply-edit-about-me-height" : ""
        }`}
      >
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          {/* Title */}
          {!isNext && (
            <div className="about-me-title">
              <Trans
                i18nKey={"profile.edit_about_me_title"}
                components={{ br: <br /> }}
              />
            </div>
          )}

          {/* Description */}
          {!isNext && (
            <div className="about-me-description">
              <Trans
                i18nKey={"profile.answer_these_questions"}
                components={{ br: <br /> }}
              />
            </div>
          )}

          {isNext && selectedQuestion?.question && (
            <div className="selected-question-icon-container">
              {getIcon(
                returnIcon(selectedQuestion?.question),
                "selected-question-icon"
              )}
            </div>
          )}

          {isNext && selectedQuestion?.question && (
            <div className="selected-question-label">
              {returnTitle(selectedQuestion?.question)}
            </div>
          )}

          {isNext && returnMaxAboutMe() > 1 && (
            <div className="selected-question-max-label">
              {t(
                selectedQuestion?.question === "hobbies"
                  ? "common.choose_up_to_n_options"
                  : "common.pick_up_to_n_options",
                {
                  max: returnMaxAboutMe(),
                }
              )}
            </div>
          )}

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : isNext ? (
            <div
              className={`about-me-body-container ${
                isMobile &&
                getSelectedQuestionAnswers()?.length >
                  utilityConst.registrationItemFlexAmount
                  ? "flex-start"
                  : "justify-center"
              }`}
            >
              {getSelectedQuestionAnswers()?.map((item, index) => {
                if (selectedQuestion?.question === "hobbies") {
                  return (
                    <div
                      className={`about-me-answer-hobby-container`}
                      key={index}
                    >
                      <div className="sub-category-about-me-label">
                        {getHobbyCategoryName(item?.sub_category)}
                      </div>

                      <div className="sub-category-about-me-content-container">
                        {item?.hobbies?.map((hobby, hobbyIndex) => {
                          return (
                            <div
                              className={`about-me-content ${
                                checkAboutMe(hobby) ? "selected" : ""
                              }`}
                              onClick={() => onSelectAnswer(hobby, index)}
                              key={hobbyIndex}
                            >
                              {hobby?.display_locale}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`about-me-answer-container ${
                        getSelectedQuestionAnswers()?.length <=
                        utilityConst.registrationItemFlexAmount
                          ? "partial-width"
                          : isMobile &&
                            getSelectedQuestionAnswers()?.length >
                              utilityConst.registrationItemFlexAmount
                          ? "fit-content"
                          : "fit-content"
                      } ${checkAboutMe(item) ? "selected" : ""}`}
                      onClick={() => onSelectAnswer(item)}
                      key={index}
                    >
                      {item?.display_locale}
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div className="about-me-body-container">
              {getAboutMeData()?.map((item, index) => (
                <div
                  className={`about-me-item-container  ${
                    selectedQuestion?.question === item?.question
                      ? "selected-about-me"
                      : ""
                  }`}
                  onClick={() => onSelectQuestion(item)}
                  key={index}
                >
                  <div className="about-me-item-label">
                    {t(`registration.about_me.${item?.question}`)}
                  </div>

                  <div className={`about-me-selected`}></div>
                </div>
              ))}
            </div>
          )}
        </div>

        {description && (
          <div className="description-container">{description}</div>
        )}
      </div>

      {isNext && (
        <div className="bottom-container">
          <div
            className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
            onClick={onNextPage}
          >
            {updateProfileLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              `${
                returnMaxAboutMe() > 1
                  ? t("profile.tor_selected", {
                      n: selectedAnswer?.length,
                      max: returnMaxAboutMe(),
                    })
                  : t("common.save")
              }`
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAboutMe;
