// General
import "./edit-birthdate.scss";
import { useEffect } from "react";
// Services
import { useUpdateEditUserProfileMutation } from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateBirthdate,
  updateAge,
} from "../../../../../redux/store/editProfileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// react-date-picker
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditBirthdate = () => {
  // API variables
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const minDate = moment().subtract(userConst.userAgeRange.min, "years");
  const maxDate = moment().subtract(userConst.userAgeRange.max, "years");

  // Redux variables
  const username = useSelector((state) => state.editProfile.username);
  const birthdate = useSelector((state) => state.editProfile.birthdate);
  const age = useSelector((state) => state.editProfile.age);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(updateBirthdate(updateProfileData?.data?.user?.birth_date));
      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Event Handlers | Button
  const onSaveEthnicity = () => {
    const obj = {
      username,
    };
    updateProfile(obj);
  };

  // Event Handlers | MUI Text Field
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "birthdate":
        dispatch(updateBirthdate(moment(event).format("YYYY-MM-DD")));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFormValid = () => {
    return birthdate;
  };

  return (
    <div id="birthdate-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div className="birthdate-title">{t("signup.date_of_birth")}</div>

          <div className="date-picker-container">
            <DatePicker
              onChange={onInputChange("birthdate")}
              value={birthdate || moment().toDate()}
              minDate={minDate.toDate()}
              maxDate={maxDate.toDate()}
              clearIcon={null}
              format={"dd/MM/yyyy"}
            />
          </div>
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveEthnicity}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditBirthdate;
