// General
import "./live-viewer.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateLiveViewerByIndex,
  updateTopFansDailyByIndex,
} from "../../../../../../../redux/store/livestreamingStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateStarSign,
  updateFitnessLevel,
  updateDominantOrSubmissive,
  updatePersonalityTraits,
  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../../../redux/store/userCardStore";
import { updateUserProfileDialog } from "../../../../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../../../../elements/custom-avatar/custom-avatar";
import Spinner from "../../../../../elements/spinner/spinner";

const LiveViewer = (props) => {
  const {
    index,
    // User View
    id,
    idInt,
    username,
    age,
    gender,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    isFollowing,
    // About Me Functions
    personalityType,
    hobbies,
    starSign,
    fitnessLevel,
    dominantOrSubmissive,
    personalityTraits,
    // Looking For Functions
    lookingForAgeRange,
    lookingForLocation,
    lookingForEthnicityRace,
    // User Utility
    shouldCallApi,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
  } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Follow user API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      switch (postFollowUserData?.status) {
        case 1:
          const obj = {
            id,
            isFollowing: true,
            index: index,
          };
          dispatch(updateLiveViewerByIndex(obj));
          dispatch(updateTopFansDailyByIndex(obj));
          break;
        case -1:
          // Already following user
          const toastObj = {
            message: postFollowUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
        default:
          break;
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | Unfollow user API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      switch (postUnfollowUserData?.status) {
        case 1:
          const obj = {
            id,
            isFollowing: false,
            index: index,
          };
          dispatch(updateLiveViewerByIndex(obj));
          dispatch(updateTopFansDailyByIndex(obj));
          break;
        case -1:
          // Already unfollowing user
          const toastObj = {
            message: postUnfollowUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
        default:
          break;
      }
      if (postUnfollowUserData?.status === 1) {
        const obj = {
          isFollowing: false,
          index: index,
        };
        dispatch(updateLiveViewerByIndex(obj));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Event Handlers | Button
  const onViewProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D6-6.6-6.6.1-Button",
      },
    });

    // User View
    dispatch(updateId(id));
    dispatch(updateIdInt(idInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateGender(gender));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updateProfileDecorations(profileDecorations));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    // dispatch(updateAboutMe(aboutMe));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // About Me Functions
    let tempAboutMeList = [];

    tempAboutMeList?.push({
      name: "personality_type",
      data: personalityType?.length > 0 ? personalityType : null,
    });
    tempAboutMeList?.push({
      name: "hobbies",
      data: hobbies?.lengtj > 0 ? hobbies : null,
    });
    tempAboutMeList?.push({
      name: "star_sign",
      data: starSign,
    });
    tempAboutMeList?.push({
      name: "fitness_level",
      data: fitnessLevel,
    });
    tempAboutMeList?.push({
      name: "dominant_or_submissive",
      data: dominantOrSubmissive,
    });
    tempAboutMeList?.push({
      name: "personality_traits",
      data: personalityTraits,
    });
    dispatch(updatePersonalityType(personalityType));
    dispatch(updateHobbies(hobbies));
    dispatch(updateFitnessLevel(fitnessLevel));
    dispatch(updateStarSign(starSign));
    dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
    dispatch(updatePersonalityTraits(personalityTraits));

    dispatch(updateAboutMe(tempAboutMeList));

    dispatch(
      updateLookingForAgeRange({
        min: lookingForAgeRange?.min,
        max: lookingForAgeRange?.max,
      })
    );
    dispatch(updateLookingForLocation(lookingForLocation));
    const temp = lookingForEthnicityRace?.map((item) => ({
      ethnicity: {
        id: item?.option?.id,
        locale: item?.option?.locale,
        display_locale: item?.option?.locale,
      },
      race: item?.sub_option
        ? {
            id: item?.sub_option?.id,
            locale: item?.sub_option?.locale,
            display_locale: item?.sub_option?.locale,
          }
        : null,
    }));
    dispatch(updateLookingForEthnicityRace(temp));
    // User Utility
    dispatch(updateShouldCallApi(shouldCallApi));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    dispatch(updateUserProfileDialog(true));
  };
  const onFollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D6-6.6-6.6.2-Button",
      },
    });

    const obj = {
      user_id: id,
    };

    postFollowUser(obj);
  };
  const onUnfollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D6-6.6-6.6.3-Button",
      },
    });

    const obj = {
      user_id: id,
    };

    postUnfollowUser(obj);
  };

  // Render Functions
  const renderFollowButton = () => {
    if (getProfileData?.data?.id !== id) {
      if (isFollowing) {
        return (
          <div className="unfollow-button" onClick={onUnfollowUser}>
            {postUnfollowUserLoading ? (
              <Spinner size={12} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.unfollow")
            )}
          </div>
        );
      } else {
        return (
          <div className="follow-button" onClick={onFollowUser}>
            {postFollowUserLoading ? (
              <Spinner size={12} isPadding={false} />
            ) : (
              t("common.follow")
            )}
          </div>
        );
      }
    }
  };

  return (
    <div id="live-tab-live-viewer-subcomponent">
      <CustomAvatar
        className="profile-photo"
        src={profilePhoto}
        role={role}
        profileBorder={profileDecorations?.profile_border_url}
        onClick={onViewProfile}
      />

      <div className="user-description-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        <div className="username">{username}</div>

        {levellingBadge && (
          <div className="levelling-badge-container">
            <img className="levelling-badge" src={levellingBadge} />
          </div>
        )}
      </div>

      <span className="spacer"></span>

      {renderFollowButton()}
    </div>
  );
};

export default LiveViewer;
