// General
import "./last-stream-viewers.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetLastStreamResultQuery,
  useLazyGetLivestreamingViewersListQuery,
} from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLastStreamViewersList,
  clearLastStreamViewersList,
} from "../../../../../redux/store/livestreamingStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ViewerList from "./viewer-list/viewer-list";

const LastStreamViewers = () => {
  // API variables
  const [
    getLastStreamSummary,
    {
      data: getLastStreamSummaryData,
      isFetching: getLastStreamSummaryFetching,
      isLoading: getLastStreamSummaryLoading,
      isSuccess: getLastStreamSummarySuccess,
      isError: getLastStreamSummaryError,
    },
  ] = useLazyGetLastStreamResultQuery();
  const [
    getLivestreamingViewersList,
    {
      data: getLivestreamingViewersListData,
      isFetching: getLivestreamingViewersListFetching,
      isLoading: getLivestreamingViewersListLoading,
      isSuccess: getLivestreamingViewersListSuccess,
      isError: getLivestreamingViewersListError,
    },
  ] = useLazyGetLivestreamingViewersListQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let viewersListView = <div></div>;

  // Redux variables
  const lastStreamViewersList = useSelector(
    (state) => state.livestreaming.lastStreamViewersList
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let viewersListParam = {
    channel_id: "",
    pagination_token: "",
  };

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(clearLastStreamViewersList());
    getLastStreamSummary(null, true);
  }, []);

  // Lifecycle | Check for update | Last Stream Summary API Response
  useEffect(() => {
    if (getLastStreamSummaryFetching || getLastStreamSummaryLoading) {
    } else if (getLastStreamSummarySuccess) {
      getLivestreamingViewersList(
        `?channel_id=${getLastStreamSummaryData?.data?.channel?.channel_id}`
      );
    } else if (getLastStreamSummaryFetching) {
    }
  }, [
    getLastStreamSummaryFetching,
    getLastStreamSummaryLoading,
    getLastStreamSummarySuccess,
    getLastStreamSummaryError,
  ]);

  // Lifecycle | Check for update | Livestreaming Viewers List API Response
  useEffect(() => {
    if (
      getLivestreamingViewersListFetching ||
      getLivestreamingViewersListLoading
    ) {
    } else if (getLivestreamingViewersListSuccess) {
      setFirstLoad(false);

      dispatch(
        updateLastStreamViewersList(
          getLivestreamingViewersListData?.data?.users || []
        )
      );
    } else if (getLivestreamingViewersListError) {
    }
  }, [
    getLivestreamingViewersListFetching,
    getLivestreamingViewersListLoading,
    getLivestreamingViewersListSuccess,
    getLivestreamingViewersListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (
      lastStreamViewersList.length < 50 &&
      getLivestreamingViewersListData?.data?.pagination?.next_cursor
    ) {
      viewersListParam.pagination_token =
        getLivestreamingViewersListData?.data?.pagination?.next_cursor;

      setTimeout(() => {
        getLivestreamingViewersList(
          `?channel_id=${getLastStreamSummaryData?.data?.channel?.channel_id}&pagination_token=${viewersListParam?.pagination_token}`
        );
      }, 1000);
    }
  };

  if (lastStreamViewersList?.length > 0) {
    viewersListView = lastStreamViewersList?.map((viewer, index) => (
      <ViewerList
        // User View
        id={viewer?.id}
        idInt={viewer?.id_int}
        username={viewer?.username}
        age={viewer?.appearance?.age}
        gender={viewer?.gender}
        location={
          viewer?.location?.display_address ||
          viewer?.location?.city ||
          viewer?.location?.state
        }
        membershipType={viewer?.membership_type}
        verifiedProfile={viewer?.is_verified_profile}
        verifiedCaller={viewer?.verified_caller}
        levellingBadge={viewer?.levelling_profile?.badge}
        livestreamingAchievementBadge={
          viewer?.live_streaming_achievements?.chat_badges_set
        }
        profilePhoto={viewer?.profile_photo?.original_photo}
        profileDecorations={viewer?.profile_decorations}
        publicPhotos={viewer?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })}
        privatePhotos={viewer?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
            isSensitive: photo?.is_sensitive,
          };
        })}
        torTags={viewer?.tor_tags}
        profileAchievementBadge={
          viewer?.live_streaming_achievements?.profile_badges
        }
        aboutMe={viewer?.aboutme}
        lookingFor={viewer?.interest}
        occupation={viewer?.occupation?.occupation}
        lifestyle={viewer?.occupation?.lifestyle_budget}
        datingBudget={viewer?.relationship?.dating_budget}
        education={viewer?.occupation?.education}
        relationship={viewer?.relationship?.relationship_status}
        smoking={viewer?.habit?.smoking}
        drinking={viewer?.habit?.drinking}
        height={viewer?.appearance?.height}
        bodyType={viewer?.appearance?.body_type}
        ethnicity={viewer?.appearance?.ethnicity}
        eyeColor={viewer?.appearance?.eye_color}
        hairColor={viewer?.appearance?.hair_color}
        children={viewer?.relationship?.children}
        // About Me Functions
        personalityType={viewer?.personality?.personality_type}
        hobbies={viewer?.habit?.hobbies}
        starSign={viewer?.personality?.star_sign}
        fitnessLevel={viewer?.habit?.fitness_level}
        dominantOrSubmissive={viewer?.personality?.dominant_or_submissive}
        personalityTraits={viewer?.personality?.personality_traits}
        // Looking For Functions
        lookingForAgeRange={viewer?.looking_fors?.age}
        lookingForLocation={viewer?.looking_fors?.locations}
        lookingForEthnicityRace={viewer?.looking_fors?.ethnicity}
        // User Utility
        shouldCallApi={true}
        privatePhotoRequested={viewer?.interaction?.private_photo_requested}
        privatePhotoApproval={viewer?.interaction?.private_photo_approval}
        requestedMyPrivatePhoto={
          viewer?.interaction?.requested_my_private_photo
        }
        approvedMyPrivatePhoto={viewer?.interaction?.approved_my_private_photo}
        isBlocked={viewer?.interaction?.is_blocked}
        isSilentInteraction={viewer.interaction?.should_silent_interactions}
        isFavorited={viewer?.interaction?.is_favorited}
        isCallEnabled={
          viewer?.private_call_settings?.enable_calls &&
          viewer?.private_call_settings?.system_private_calls_enabled
        }
        isSugarbaby={
          viewer?.role === userConst.userRole.sugarbaby ||
          viewer?.role === userConst.userRole.maleSugarbaby
        }
        role={viewer?.role}
        key={index}
      />
    ));
  } else if (
    firstLoad &&
    (getLivestreamingViewersListFetching || getLivestreamingViewersListLoading)
  ) {
    viewersListView = <Spinner />;
  } else {
    return;
  }

  return (
    <div id="last-stream-summary-last-stream-viewers-subcomponent">
      <div className="padding-container">
        <div className="last-viewer-label">
          {t("settings.last_n_viewers", { n: 50 })}
        </div>

        <div className="viewers-list-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={infiniteScrollFunc}
            hasMore={
              lastStreamViewersList.length < 50 &&
              getLivestreamingViewersListData?.data?.pagination?.next_cursor
                ? true
                : false
            }
            loader={<Spinner key={0} />}
            useWindow={false}
          >
            {viewersListView}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default LastStreamViewers;
