// General
import "./live-tab.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetLivestreamingViewersListQuery,
  useLazyGetLivestreamingCoAnchorViewersListQuery,
} from "../../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../../const/routeConst";
import userConst from "../../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetLiveViewers,
  updateLiveViewers,
} from "../../../../../../redux/store/livestreamingStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../../elements/spinner/spinner";
import EmptyList from "./empty-list/empty-list";
import LiveViewer from "./live-viewer/live-viewer";

const LiveTab = () => {
  // API variables
  const [
    getLivestreamingViewersList,
    {
      data: livestreamingViewersListData,
      error: livestreamingViewersListErrorData,
      isFetching: livestreamingViewersListFetching,
      isLoading: livestreamingViewersListLoading,
      isSuccess: livestreamingViewersListSuccess,
      isError: livestreamingViewersListError,
    },
  ] = useLazyGetLivestreamingViewersListQuery();
  const [
    getLivestreamingCoAnchorViewersList,
    {
      data: livestreamingCoAnchorViewersListData,
      error: livestreamingCoAnchorViewersListErrorData,
      isFetching: livestreamingCoAnchorViewersListFetching,
      isLoading: livestreamingCoAnchorViewersListLoading,
      isSuccess: livestreamingCoAnchorViewersListSuccess,
      isError: livestreamingCoAnchorViewersListError,
    },
  ] = useLazyGetLivestreamingCoAnchorViewersListQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let liveViewerListView = <div></div>;

  // Redux variables
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const liveViewers = useSelector((state) => state.livestreaming.liveViewers);
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let liveViewerParam = {
    channel_id: channelId,
    pagination_token: null,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(resetLiveViewers());
  }, []);

  // Lifecycle | Check for update | isCoAnchor
  useEffect(() => {
    dispatch(resetLiveViewers());

    if (isCoAnchor) {
      getLivestreamingCoAnchorViewersList(
        `?channel_id=${liveViewerParam.channel_id}${
          liveViewerParam.pagination_token
            ? `&pagination_token=${liveViewerParam.pagination_token}`
            : ""
        }`
      );
    } else if (isCoAnchor === false) {
      getLivestreamingViewersList(
        `?channel_id=${liveViewerParam.channel_id}${
          liveViewerParam.pagination_token
            ? `&pagination_token=${liveViewerParam.pagination_token}`
            : ""
        }`
      );
    }
  }, [isCoAnchor]);

  // Lifecycle | Check for update | Viewer List API Response
  useEffect(() => {
    if (livestreamingViewersListFetching || livestreamingViewersListLoading) {
    } else if (livestreamingViewersListSuccess) {
      setFirstLoad(false);

      if (livestreamingViewersListData?.status === 1) {
        liveViewerParam.pagination_token =
          livestreamingViewersListData?.data?.pagination?.next_cursor;

        if (livestreamingViewersListData?.data?.users) {
          // Update live viewers list
          const liveViewersData = [
            ...liveViewers,
            ...livestreamingViewersListData?.data?.users,
          ];
          dispatch(updateLiveViewers(liveViewersData));
        }
      }
    } else if (livestreamingViewersListError) {
    }
  }, [
    livestreamingViewersListFetching,
    livestreamingViewersListLoading,
    livestreamingViewersListSuccess,
    livestreamingViewersListError,
  ]);

  useEffect(() => {
    if (
      livestreamingCoAnchorViewersListFetching ||
      livestreamingCoAnchorViewersListLoading
    ) {
    } else if (livestreamingCoAnchorViewersListSuccess) {
      setFirstLoad(false);

      if (livestreamingCoAnchorViewersListData?.status === 1) {
        liveViewerParam.pagination_token =
          livestreamingCoAnchorViewersListData?.data?.pagination?.next_cursor;

        if (livestreamingCoAnchorViewersListData?.data?.users) {
          // Update live viewers list
          const liveViewersData = [
            ...liveViewers,
            ...livestreamingCoAnchorViewersListData?.data?.users,
          ];
          dispatch(updateLiveViewers(liveViewersData));
        }
      }
    } else if (livestreamingCoAnchorViewersListError) {
    }
  }, [
    livestreamingCoAnchorViewersListFetching,
    livestreamingCoAnchorViewersListLoading,
    livestreamingCoAnchorViewersListSuccess,
    livestreamingCoAnchorViewersListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (livestreamingViewersListData?.data?.pagination?.next_cursor) {
      liveViewerParam.pagination_token =
        livestreamingViewersListData?.data?.pagination?.next_cursor;

      setTimeout(() => {
        if (isCoAnchor) {
          getLivestreamingCoAnchorViewersList(
            `?channel_id=${liveViewerParam.channel_id}${
              liveViewerParam.pagination_token
                ? `&pagination_token=${liveViewerParam.pagination_token}`
                : ""
            }`
          );
        } else {
          getLivestreamingViewersList(
            `?channel_id=${liveViewerParam.channel_id}${
              liveViewerParam.pagination_token
                ? `&pagination_token=${liveViewerParam.pagination_token}`
                : ""
            }`,
            true
          );
        }
      }, 1000);
    }
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetLiveViewers());
    setFirstLoad(true);

    return new Promise((resolve) => {
      if (isCoAnchor) {
        resolve(
          getLivestreamingCoAnchorViewersList(
            `?channel_id=${liveViewerParam.channel_id}${
              liveViewerParam.pagination_token
                ? `&pagination_token=${liveViewerParam.pagination_token}`
                : ""
            }`
          )
        );
      } else {
        resolve(
          getLivestreamingViewersList(
            `?channel_id=${liveViewerParam.channel_id}${
              liveViewerParam.pagination_token
                ? `&pagination_token=${liveViewerParam.pagination_token}`
                : ""
            }`
          )
        );
      }
    });
  };

  // Render Functions
  if (
    firstLoad &&
    (livestreamingViewersListFetching || livestreamingViewersListLoading)
  ) {
    return <Spinner />;
  } else if (livestreamingViewersListError) {
    if (livestreamingViewersListErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }

  // Render Functions | Success
  if (liveViewers?.length > 0) {
    liveViewerListView = (
      <div className="live-viewer-list-container">
        {liveViewers?.map((user, index) => (
          <LiveViewer
            index={index}
            // User View
            id={user?.id}
            idInt={user?.id_int}
            username={user?.username}
            age={user?.appearance?.age}
            gender={user?.gender}
            location={
              user?.location?.display_address ||
              user?.location?.city ||
              user?.location?.state
            }
            membershipType={user?.membership_type}
            verifiedProfile={user?.is_verified_profile}
            verifiedCaller={user?.verified_caller}
            levellingBadge={user?.levelling_profile?.badge}
            livestreamingAchievementBadge={
              user?.live_streaming_achievements?.chat_badges_set
            }
            profilePhoto={user?.profile_photo?.original_photo}
            profileDecorations={user?.profile_decorations}
            publicPhotos={user?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={user?.private_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                thumbnailData: photo?.thumbnail_data,
                isSensitive: photo?.is_sensitive,
              };
            })}
            torTags={user?.tor_tags}
            profileAchievementBadge={
              user?.live_streaming_achievements?.profile_badges
            }
            aboutMe={user?.aboutme}
            lookingFor={user?.interest}
            occupation={user?.occupation?.occupation}
            lifestyle={user?.occupation?.lifestyle_budget}
            datingBudget={user?.relationship?.dating_budget}
            education={user?.occupation?.education}
            relationship={user?.relationship?.relationship_status}
            smoking={user?.habit?.smoking}
            drinking={user?.habit?.drinking}
            height={user?.appearance?.height}
            bodyType={user?.appearance?.body_type}
            ethnicity={user?.appearance?.ethnicity}
            eyeColor={user?.appearance?.eye_color}
            hairColor={user?.appearance?.hair_color}
            children={user?.relationship?.children}
            isFollowing={user?.live_streaming_meta?.is_following}
            // About Me Functions
            personalityType={user?.personality?.personality_type}
            hobbies={user?.habit?.hobbies}
            starSign={user?.personality?.star_sign}
            fitnessLevel={user?.habit?.fitness_level}
            dominantOrSubmissive={user?.personality?.dominant_or_submissive}
            personalityTraits={user?.personality?.personality_traits}
            // Looking For Functions
            lookingForAgeRange={user?.looking_fors?.age}
            lookingForLocation={user?.looking_fors?.locations}
            lookingForEthnicityRace={user?.looking_fors?.ethnicity}
            // User Utility
            shouldCallApi={true}
            privatePhotoRequested={user?.interaction?.private_photo_requested}
            privatePhotoApproval={user?.interaction?.private_photo_approval}
            requestedMyPrivatePhoto={
              user?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              user?.interaction?.approved_my_private_photo
            }
            isBlocked={user?.interaction?.is_blocked}
            isSilentInteraction={user?.interaction?.should_silent_interactions}
            isFavorited={user?.interaction?.is_favorited}
            isCallEnabled={
              user?.private_call_settings?.enable_calls &&
              user?.private_call_settings?.system_private_calls_enabled
            }
            isSugarbaby={
              user?.role === userConst.userRole.sugarbaby ||
              user?.role === userConst.userRole.maleSugarbaby
            }
            role={user?.role}
            key={index}
          />
        ))}
      </div>
    );
  } else {
    return <EmptyList />;
  }

  return (
    <div id="viewer-info-live-tab-subcomponent">
      <PullToRefresh
        className="pull-to-refresh-container"
        onRefresh={pullToRefreshFunc}
        refreshingContent={t("common.release")}
        pullingContent={t("common.pull_to_refresh")}
        isPullable={
          !livestreamingViewersListFetching && !livestreamingViewersListLoading
        }
      >
        <div className="padding-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={infiniteScrollFunc}
            hasMore={
              livestreamingViewersListData?.data?.pagination?.next_cursor
                ? true
                : false
            }
            loader={<Spinner key={0} />}
            useWindow={false}
          >
            {liveViewerListView}
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </div>
  );
};

export default LiveTab;
